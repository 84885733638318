import classNames from 'classnames';
import React, { Component } from 'react';
import Select from 'react-select/async';

import { searchBankAccount } from '../services/BankAccountService';

const customControlStyles = base => ({
    height: 30,
    minHeight: 30,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc'
});

class BankAccountSelect extends Component {

    constructor(props) {
        super(props)
        this.searchSelectRef = React.createRef();
        this.searchBankAccount = this.searchBankAccount.bind(this);
    }

    componentDidMount() {
        if (this.props.autoFocus) this.searchSelectRef.current.focus();
    }
    searchBankAccount(keyword, callback) {
        searchBankAccount(keyword)
            .then((response) => {
                let bankAccounts = response.data.bank_accounts;
                let options = bankAccounts.map(bankAccount => {
                    let newBankAccount = { value: bankAccount.id, label: `${bankAccount.name}` };

                    return newBankAccount;
                });
                callback(options);
            });
    }

    render() {
        let sVal = null;
        if (this.props.value !== undefined) {
            sVal = this.props.value;
        }


        return (
            <div style={{ display: 'block', width: this.props.width }}>
                <Select
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    ref={this.searchSelectRef}
                    onChange={(bankAccount, action) => {
                        (this.props.onChange)(bankAccount);
                    }}
                    isSearchable={true}
                    placeholder={this.props.placeholder}
                    loadOptions={this.searchBankAccount}
                    noOptionsMessage={() => { return "Type to search" }}
                    value={sVal}
                    isClearable={this.props.isClearable}
                    aria-invalid={this.props.invalid ? 'true' : 'false'}
                    name={this.props.name}
                    className={classNames({
                        'is-invalid': this.props.invalid,
                        'is-valid': this.props.valid
                    })}
                    classNames={{
                        control: (state) => 'react-select-form-control',
                    }}
                    styles={{
                        control: customControlStyles,
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    onBlur={() => this.props.onBlur({ target: { name: this.props.name, value: sVal } })}
                    isDisabled={this.props.disabled}
                />
            </div>
        )
    }
}

BankAccountSelect.defaultProps = {
    autoFocus: false,
    onBlur: () => { }
};

export default BankAccountSelect;


{/*
Usage:
    <BankAccountSelect
        callback={(r) => {
            setFieldValue('bankAccount_id', r.value);
            setBankAccount(r)
        }}
        value={bankAccount}
        invalid={(errors.bankAccount_id && touched.bankAccount_id) || !!status?.apiErrors?.bankAccount_id}
        name="bankAccount_id"
        valid={(!errors.bankAccount_id && touched.bankAccount_id) && !status?.apiErrors?.bankAccount_id}
        onBlur={handleBlur}
    />
*/}
