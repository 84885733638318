export function laravelValidate(values, collection, messages = {}) {
  let errors = {};
  Object.keys(collection).forEach(field => {
    const rules = collection[field];
    const fieldValue = values[field];

    const rulesArray = rules instanceof Array ? rules : rules.split('|');

    if (rulesArray.indexOf('nullable') == -1 || (!!fieldValue)) {
      rulesArray.forEach(rule => {
        const [f, p] = rule.split(':')

        const [r, e] = eval('validate' + capitalizeFirstLetter(f))(values, field, rulesArray, fieldValue, p?.split(','), messages?.[field]?.[f]);

        if (!r && !errors[field]) errors[field] = e;
      });
    }
  });
  console.log(errors)
  return errors;
}

function validateNullable(allValues, name, allRules, value) {
  return [true, ''];
}

function validateRequired(allValues, name, allRules, value, message) {
  return [!!value || value === 0, message ?? 'Required'];
}

function validateString(allValues, name, allRules, value, message) {
  return [true, 'Should be string'];
}


function validateEmail(allValues, name, allRules, value, message) {
  return [/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value), message ?? 'Should be an email'];
}

function validateConfirmed(allValues, name, allRules, value, message) {
  return [allValues[name + '_confirmation'] === value, message ?? 'Should match ' + name + ' confirmation'];
}

function validateNumeric(allValues, name, allRules, value, message) {
  return [!isNaN(value), message ?? 'Should be numbers'];
}

function validateDate(allValues, name, allRules, value, message) {
  return [!isNaN(new Date(value)), message ?? 'Should be a date'];
}

function validateExists(allValues, name, allRules, value, parameters, message) {
  return [true, ''];
}

function validateUnique(allValues, name, allRules, value, parameters, message) {
  return [true, ''];
}

function validateMin(allValues, name, allRules, value, [min], message) {
  if (allRules.indexOf('numeric') !== -1 || allRules.indexOf('integer') !== -1) return [value >= min, message ?? 'Should be at least ' + min]

  // For string, array
  return [value.length >= min, message ?? 'Should be at least ' + min]
}

function validateMax(allValues, name, allRules, value, [max], message) {
  if (value == undefined) return [true, ''];

  console.log(value, max, value <= max);
  if (allRules.indexOf('numeric') !== -1 || allRules.indexOf('integer') !== -1) return [parseFloat(value) <= parseFloat(max), message ?? 'Should be at max ' + max]

  if (allRules.indexOf('string') !== -1) return [value.toString().length <= max, message ?? 'Should be at max ' + max]

  // For array
  return [value.length <= max, message ?? 'Should be at max ' + max]
}

function validateFile(allValues, name, allRules, value, pValues, message) {
  return [value instanceof File, message ?? 'Should be a file'];
}

function validateArray(allValues, name, allRules, value, pValues, message) {
  return [value instanceof Array || name.substr(-2) == '[]' || name.indexOf('.') !== -1, message ?? 'Should be an array'];
}

function validateIn(allValues, name, allRules, value, pValues, message) {
  return [pValues.indexOf(value) !== -1, message ?? 'Should be from set {' + pValues.join(', ') + '}'];
}

// function validateMimetypes(allValues, name, allRules, value, pValues, message) {
//     return [(pValues.filter(p => value.type.match(p))) > 0, 'Should be of type ' + pValues.join(',')];
// }

function validateRequired_if(allValues, name, allRules, value, [oName, oValue], message) {
  return [allValues[oName] == oValue ? !!value : true, message ?? `Required`];
}

function validateRequired_with(allValues, name, allRules, value, [oName], message) {
  return [!!allValues[oName] ? !!value : true, message ?? `Required`];
}

function validateRequired_without(allValues, name, allRules, value, [oName], message) {
  return [!(allValues[oName] === undefined || allValues[oName] === null) || !(value === undefined || value === ''), message ?? 'Required'];
}

// function validateRequired_without(allValues, name, allRules, value, [oName], message) {
//   return [!(allValues[oName] === undefined) ? !!value : false, message ?? `Required`];
// }


function validateZip(allValues, name, allRules, value, message) {
  return [/^\d{5}(-\d{4})?$/.test(value), message ?? 'Should be a zip code'];
}

function validateRegex(allValues, name, allRules, value, [pattern], message) {
  return [RegExp(pattern).test(value), message ?? 'Should be a valid value'];
}

function validateDigits(allValues, name, allRules, value, [digits], message) {
  return [value.toString().length == digits, message ?? 'Should be ' + digits + ' digits'];
}

function validateSame(allValues, name, allRules, value, [oName], message) {

  return [allValues[oName] == value, message ?? `Should match ` + oName];
}

function validateJson(allValues, name, allRules, value, message) {
  if (typeof value === 'object' && value !== null) {
    return [true, ''];
  }

  if (typeof value === 'string') {
    try {
      JSON.parse(value);
      return [true, ''];
    } catch (e) {
      return [false, message ?? 'Should be valid JSON'];
    }
  }

  return [false, message ?? 'Should be valid JSON'];
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
