import axios from '../httpClient';

const listFcaBudgetLines = (fundsControlAccountId) => {
    let link = '/fca-budget-lines?';

    if (!!fundsControlAccountId) link += `&funds_control_account_id=${fundsControlAccountId}`;

    return axios.get(link);
}

const showFcaBudgetLine = (id) => axios.get(`/fca-budget-lines/${id}`);
const searchFcaBudgetLine = (keyword, fundsControlAccountId = undefined) => {
    let link = `/fca-budget-lines?search=${keyword}`;

    if (!!fundsControlAccountId) link += `&funds_control_account_id=${fundsControlAccountId}`;

    return axios.get(link);
}

const storeFcaBudgetLine = (data) => axios.post('/fca-budget-lines', data);

const uploadFcaBudgetLine = (data) => axios.post('/fca-budget-lines/upload', data, { headers: { 'Content-Type': 'multipart/form-data' } });

const updateFcaBudgetLine = (id, data) => axios.put(`/fca-budget-lines/${id}`, data);

const deleteFcaBudgetLine = (id) => axios.delete(`/fca-budget-lines/${id}`);

const deleteAllFcaBudgetLines = (data) => {
  return axios.delete(`/fca-budget-lines/all`, {
    headers: { 'Content-Type': 'application/json' },
    data: data
  });
};

const parsePdfTable = (data) => axios.post(`../local-storage/table-parser/`, data, { headers: { 'Content-Type': 'multipart/form-data' } });

export { listFcaBudgetLines, storeFcaBudgetLine, uploadFcaBudgetLine, updateFcaBudgetLine, searchFcaBudgetLine, showFcaBudgetLine, deleteFcaBudgetLine, parsePdfTable, deleteAllFcaBudgetLines };
