import axios from '../httpClient';

const listFcaAdjustments = (fundsControlAccountId, fcaInvestmentId) => {
    let link = '/fca-investment-adjustments?';

    if (!!fundsControlAccountId) link += `&funds_control_account_id=${fundsControlAccountId}`;
    if (!!fcaInvestmentId) link += `&fca_investment_id=${fcaInvestmentId}`;

    return axios.get(link);
}

const showFcaAdjustment = (id) => axios.get(`/fca-investment-adjustments/${id}`);
const searchFcaAdjustment = (keyword, fundsControlAccountId = undefined) => {
    let link = `/fca-investment-adjustments?search=${keyword}`;

    if (!!fundsControlAccountId) link += `&funds_control_account_id=${fundsControlAccountId}`;

    return axios.get(link);
}

const storeFcaAdjustment = (data) => axios.post('/fca-investment-adjustments', data);

const updateFcaAdjustment = (id, data) => axios.put(`/fca-investment-adjustments/${id}`, data);

const deleteFcaAdjustment = (id) => axios.delete(`/fca-investment-adjustments/${id}`);

export { deleteFcaAdjustment, listFcaAdjustments, searchFcaAdjustment, showFcaAdjustment, storeFcaAdjustment, updateFcaAdjustment };
