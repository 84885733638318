import { cilFingerprint } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { CButton, CImage, CTooltip } from '@coreui/react'
import add from './../assets/images/fca/add.png'
import floppy from './../assets/images/fca/floppy.png'
import trash from './../assets/images/fca/trash.png'
import refresh from './../assets/images/fca/refresh.png'

export const AddIconButton = ({
  onClick,
  data,
  isDisabled = false,
  stylesheet = {},
  imgStyles = {},
  title="Create"
}) => {
  return (
    <CTooltip content={title} placement="top">
      <CButton
        className="btn-sm"
        style={stylesheet}
        onClick={() => onClick(data)}
        disabled={isDisabled}
      >
        <CImage src={add} style={imgStyles} />
      </CButton>
    </CTooltip>
  )
}

export const EditIconButton = ({
  onClick,
  data,
  isDisabled = false,
  stylesheet = {},
  imgStyles = {},
}) => {
  return (
    <CTooltip content="Save" placement="top">
      <CButton
        className="edit"
        style={stylesheet}
        onClick={() => onClick(data)}
        disabled={isDisabled}
      >
        <CImage src={floppy} style={imgStyles} />
      </CButton>
    </CTooltip>
  )
}

export const DeleteIconButton = ({
  onClick,
  data,
  isDisabled = false,
  stylesheet = {},
  imgStyles = {},
}) => {
  return (
    <CTooltip content="Delete" placement="top">
      <CButton
        className="delete"
        style={stylesheet}
        onClick={() => onClick(data)}
        disabled={isDisabled}
      >
        <CImage src={trash} style={imgStyles} />
      </CButton>
    </CTooltip>
  )
}
export const AnalyzeIconButton = ({
  onClick,
  data,
  isDisabled = false,
  stylesheet = {},
  imgStyles = {},
}) => {
  return (
    <CTooltip content="Analyze" placement="top">
      <CButton
        className="edit"
        style={stylesheet}
        onClick={() => onClick(data)}
        disabled={isDisabled}
      >
        <CIcon icon={cilFingerprint} style={imgStyles} />
      </CButton>
    </CTooltip>
  )
}

export const RefreshIconButton = ({
  onClick,
  data,
  isDisabled = false,
}) => {
  return (
    <CTooltip content="Refresh" placement="top">
      <CButton
        onClick={() => onClick(data)}
        disabled={isDisabled}
      >
        <CImage src={refresh} />
      </CButton>
    </CTooltip>
  )
}
