import classNames from 'classnames';
import React, { Component } from 'react';
import Select from 'react-select';
import { states } from '../constants/states';

const customControlStyles = base => ({
    height: 30,
    minHeight: 30,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc'
});

class StateSelect extends Component {

    constructor(props) {
        super(props)
        this.searchSelectRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.autoFocus) this.searchSelectRef.current.focus();
    }

    render() {
        let sVal = null;
        if (this.props.value !== undefined) {
            sVal = this.props.value;
        }


        return (
            <div style={{ display: 'block', width: this.props.width }}>
                <Select
                    components={{
                        IndicatorSeparator: () => null
                    }}
                    ref={this.searchSelectRef}
                    onChange={(state, action) => {
                        (this.props.onChange)(state);
                    }}
                    isSearchable={true}
                    placeholder={this.props.placeholder}
                    options={states.map(s => { return { label: s, value: s } })}
                    noOptionsMessage={() => { return "Type to search" }}
                    value={sVal}
                    isClearable={this.props.isClearable}
                    aria-invalid={this.props.invalid ? 'true' : 'false'}
                    name={this.props.name}
                    className={classNames({
                        'is-invalid': this.props.invalid,
                        'is-valid': this.props.valid
                    })}
                    classNames={{
                        control: (state) => 'react-select-form-control',
                    }}
                    styles={{
                        control: customControlStyles,
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }}
                    onBlur={() => this.props.onBlur({ target: { name: this.props.name, value: sVal } })}
                    isDisabled={this.props.disabled}
                />
            </div>
        )
    }
}

StateSelect.defaultProps = {
    autoFocus: false,
    onBlur: () => { }
};

export default StateSelect;


{/* 
Usage:
    <StateSelect
        callback={(r) => {
            setFieldValue('state_id', r.value);
            setState(r)
        }}
        value={state}
        invalid={(errors.state_id && touched.state_id) || !!status?.apiErrors?.state_id}
        name="state_id"
        valid={(!errors.state_id && touched.state_id) && !status?.apiErrors?.state_id}
        onBlur={handleBlur}
    />
*/}