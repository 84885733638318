import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CContainer,
  CImage,
  CTableBody,
  CTableDataCell,
  CTableFoot,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTooltip,
} from '@coreui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { AppFooter, AppHeader, AppSidebar } from 'src/components'
import { formatCurrency } from 'src/currencyFormatter'
import { reportFcaBalanceSheet } from 'src/services/ReportService'
import FundsControlAccountSelect from 'src/utils/FundsControlAccountSelect'
import floppy from './../assets/images/fca/floppy.png'

import { cilFile } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { API_URL } from 'src/config'
import ExcelExport from 'src/utils/ExcelExport'
import moment from 'src/utils/TZMoment'
import axios from '../httpClient'
import refresh from './../assets/images/fca/refresh.png'
import { SERVICE_TYPE_FUND_CONTROL_CHECKS } from 'src/utils/LmfceConstants'
import { listFcaInvestments, listFcaInvestmentsSummary } from 'src/services/FcaInvestmentService'
import { listFcaBudgetLines } from 'src/services/FcaBudgetLineService'
import InvestmentSummaryTable from 'src/components/fundsControl/investments/InvestmentSummary'
import ViewFcaInvestment from 'src/components/fundsControl/investments/ViewInvestment'
import BankAccountSelect from 'src/utils/BankAccountSelect'
import { updateFundsControlAccount } from 'src/services/FundsControlAccountService'
import { toast } from 'react-toastify'
import { handleApiErrors } from 'src/utils/HandleErrors'

const FcaBalanceSheet = ({ fcaData, fca = false, fundsControlAccountId, refetchFCA }) => {
  const [fundsControlAccount, setFundsControlAccount] = useState(undefined)
  const [response, setResponse] = useState(undefined)
  const tableRef = useRef(null)
  const [accountingSummary, setAccountingSummary] = useState([])
  const [totals, setTotals] = useState({
    startingPrepaidAmount: 0,
    currentPrepaidAmount: 0,
    startingInvestmentAmount: 0,
    currentInvestmentAmount: 0,
    spentBudget: 0,
    inProcessBudget: 0,
  })
  const [fcaInvestments, setFcaInvestments] = useState([])
  const [bankAccount, setBankAccount] = useState(undefined)

  const loadReport = (fundsControlAccountId) => {
    reportFcaBalanceSheet(fundsControlAccountId).then((response) => {
      setResponse(response.data)
    })
  }

  useEffect(() => {
    if (fcaData) {
      setBankAccount({ value: fcaData?.bank_account?.id, label: fcaData?.bank_account?.name })
    }
  }, [fundsControlAccountId])

  const changeFcaBankAccount = async () => {
    try {
      const response = await updateFundsControlAccount(fcaData.id, {
        bank_account_id: bankAccount.value,
      })
      if (response.status == 200) {
        refetchFCA()
        return toast.success('FC Bank Account updated successfully')
      }
    } catch (error) {
      handleApiErrors(error)
      console.error('Error:', error)
    }
  }

  const fetchInvestments = useCallback(() => {
    listFcaInvestmentsSummary(fundsControlAccountId)
      .then((response) => {
        const investments = response.data.fca_investments_accounting_summary

        const aggregatedOther = {
          id: 'aggregated-other',
          fca_party_id: null,
          description: 'Aggregated Other Records',
          starting_investment_amount: 0,
          current_investment_amount: 0,
          spent_budget: null,
          in_process_budget: null,
          fca_party: {
            id: null,
            type: 'Other',
            party: {
              id: null,
              name: 'Merged Other Records',
            },
          },
        }

        const transformedInvestments = []

        investments.forEach((investment) => {
          let type = investment.type

          if (type === 'Owner') {
            type = 'Borrower'
          } else if (type !== 'Lender' && type !== 'Borrower') {
            aggregatedOther.starting_investment_amount += parseFloat(
              investment.sum_starting_investment_amount || 0,
            )
            aggregatedOther.current_investment_amount += parseFloat(
              investment.sum_current_investment_amount || 0,
            )
            return
          }

          transformedInvestments.push({
            ...investment,
            starting_investment_amount: investment.sum_starting_investment_amount,
            current_investment_amount: investment.sum_current_investment_amount,
            in_process_budget: investment.sum_in_process_budget,
            fca_party: {
              id: investment.fca_party_id,
              type,
            },
          })
        })

        if (
          aggregatedOther.starting_investment_amount > 0 ||
          aggregatedOther.current_investment_amount > 0
        ) {
          transformedInvestments.push(aggregatedOther)
        }

        setFcaInvestments(transformedInvestments)
      })
      .catch((error) => {
        console.error('Error fetching investments:', error)
      })
  }, [fundsControlAccountId])

  useEffect(() => {
    if (fundsControlAccountId) {
      loadReport(fundsControlAccountId)
      setFundsControlAccount(fundsControlAccountId)
    }
    if (fca) {
      fetchInvestments()
    }
  }, [fundsControlAccountId])

  let sVoucher = 0.0,
    sDeposit = 0.0,
    lastBalance = 0.0

  const formatAddress = (...addressParts) => {
    return addressParts.filter(Boolean).join(', ')
  }

  useEffect(() => {
    const totals = fcaInvestments.reduce(
      (acc, investment) => {
        acc.startingPrepaidAmount += parseFloat(investment.starting_prepaid_amount || 0)
        acc.currentPrepaidAmount += parseFloat(investment.current_prepaid_amount || 0)
        acc.startingInvestmentAmount += parseFloat(investment.starting_investment_amount || 0)
        acc.currentInvestmentAmount += parseFloat(investment.current_investment_amount || 0)
        acc.spentBudget += parseFloat(investment.spent_budget || 0)
        acc.inProcessBudget += parseFloat(investment.in_process_budget || 0)
        return acc
      },
      {
        startingPrepaidAmount: 0,
        currentPrepaidAmount: 0,
        startingInvestmentAmount: 0,
        currentInvestmentAmount: 0,
        spentBudget: 0,
        inProcessBudget: 0,
      },
    )
    setTotals(totals)
  }, [fcaInvestments])

  console.log("dad",response?.rows)

  return (
    <div>
      {!fca && <AppSidebar />}
      <div className={`wrapper d-flex flex-column min-vh-100 ${!fca ? 'bg-light' : ''}`}>
        {!fca && <AppHeader heading="FC Balance Sheet" />}
        <div className="d-flex flex-row align-items-center">
          <CContainer fluid>
            <div className="d-flex justify-content-end">
              <div className="filters w-100">
                <CButtonGroup className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    {fca && (
                      <div className="d-flex align-items-center">
                        <label className="me-1">Bank Account: </label>
                        <BankAccountSelect
                          placeholder="Select Bank Account"
                          width="20rem"
                          name="bank_account_id"
                          className="select-custom-class"
                          onChange={(bankAccount) => {
                            setBankAccount(bankAccount)
                          }}
                          value={bankAccount}
                        />
                        <CButton
                          onClick={() => changeFcaBankAccount()}
                          className="edit"
                          style={{
                            background: '#D2E0DD',
                            border: 'none',
                            marginLeft: '10px',
                            borderRadius: '8px',
                          }}
                          imgStyles={{ width: '1.2rem', height: '1.2rem' }}
                        >
                          <CImage src={floppy} />
                        </CButton>
                      </div>
                    )}
                  </div>
                  <div className="d-flex">
                    {!fca && (
                      <>
                        <label className="me-2">FC: </label>
                        <FundsControlAccountSelect
                          placeholder="Select Fund Control"
                          reportsLabel={true}
                          width="70rem"
                          name="funds_control_account_id"
                          className="select-custom-class"
                          serviceType={SERVICE_TYPE_FUND_CONTROL_CHECKS}
                          onChange={(fundsControlAccount) => {
                            setFundsControlAccount(fundsControlAccount)
                            loadReport(fundsControlAccount.value)
                          }}
                          value={fundsControlAccount}
                        />
                      </>
                    )}
                    <CTooltip content="Requery" placement="top">
                      <CButton
                        className="ms-3"
                        onClick={() => {
                          loadReport(fundsControlAccount?.value || fundsControlAccountId)
                        }}
                      >
                        {' '}
                        <CImage src={refresh} />
                      </CButton>
                    </CTooltip>
                    <ExcelExport
                      fileName="FC Balance Sheet"
                      tableRef={tableRef}
                      isDisabled={!response?.rows || response?.rows?.length == 0}
                    />
                    <CButton
                      disabled={!response?.rows || response?.rows?.length == 0}
                      style={{ background: '#025346', marginLeft: '.5rem' }}
                      onClick={() => {
                        axios({
                          url: `${API_URL}/reports/fca-balance-sheet?funds_control_account_id=${
                            fundsControlAccount.value || fundsControlAccountId
                          }&output=pdf`,
                          method: 'GET',
                          responseType: 'blob',
                        }).then((response) => {
                          const href = URL.createObjectURL(response.data)
                          const link = document.createElement('a')
                          link.href = href
                          link.setAttribute('download', 'FCABalanceSheet.pdf')
                          document.body.appendChild(link)
                          link.click()
                          document.body.removeChild(link)
                          URL.revokeObjectURL(href)
                        })
                      }}
                    >
                      Export PDF <CIcon icon={cilFile} />{' '}
                    </CButton>
                  </div>
                </CButtonGroup>
              </div>
            </div>
            {fcaInvestments.length > 0 && (
              <table className="table table-sm bordered hover new-table">
                <thead>
                  <tr>
                    <th style={{ width: '7rem' }}>Type</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Org. Amount</th>
                    <th>Changes</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Current Amount</th>
                    <th>In Process</th>
                    <th>Disbursed</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Remaining Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {fcaInvestments.map((fcaInvestment) => (
                    <ViewFcaInvestment
                      key={fcaInvestment.id}
                      fundsControlAccountId={fundsControlAccountId}
                      fcaInvestments={fcaInvestments}
                      singlefcaInvestment={fcaInvestment}
                      updateRecords={false}
                    />
                  ))}
                </tbody>
                <tfoot>
                  <tr className="table-footer">
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>{formatCurrency(totals.startingInvestmentAmount)}</td>
                    <td>
                      {formatCurrency(
                        totals.currentInvestmentAmount - totals.startingInvestmentAmount,
                      )}
                    </td>
                    <td>{formatCurrency(totals.currentInvestmentAmount)}</td>
                    <td>{formatCurrency(totals.inProcessBudget)}</td>
                    <td>{formatCurrency(totals.spentBudget)}</td>
                    <td>
                      {formatCurrency(
                        totals.currentInvestmentAmount -
                          totals.inProcessBudget -
                          totals.spentBudget,
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            )}

            <CCard className="listing form_modal bordered" ref={tableRef}>
              <CCardBody>
                <table className="p-4">
                  <tr>
                    <td style={{ width: '7rem' }}>Order Date:</td>
                    <td style={{ width: '7rem' }}>
                      {response?.funds_control_account.order_date &&
                        moment(response?.funds_control_account.order_date).format('m/d/Y')}
                    </td>
                    <td style={{ width: '7rem' }}>Closing Date:</td>
                    <td style={{ width: '7rem' }}>
                      {response?.funds_control_account.closing_date &&
                        moment(response?.funds_control_account.closing_date).format('m/d/Y')}
                    </td>
                  </tr>
                  <tr>
                    <td>Location:</td>
                    <td>{response?.funds_control_account.address}</td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td>
                      {response?.funds_control_account.city},{' '}
                      {response?.funds_control_account.state}{' '}
                      {response?.funds_control_account.zip_code}
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
                <table className="p-5 mx-auto">
                  <tbody>
                    {response?.funds_control_account.fca_parties
                      .filter((p) => ['Lender', 'Owner', 'Contractor'].includes(p.type))
                      .map((fcaParty, index) => {
                        return [
                          <tr key={`{fcaParty.id}-{0}`}>
                            <td className="text-start">{fcaParty.type}</td>
                            <td style={{ paddingLeft: '10px' }}>{fcaParty.party.name}</td>
                            <td className="text-end" style={{ paddingLeft: '5rem' }}>
                              Telephone:
                            </td>
                            <td>{fcaParty.party.telephone_one}</td>
                          </tr>,
                          <tr key={`{fcaParty.id}-{1}`}>
                            <td className="text-end">Address:</td>
                            <td style={{ paddingLeft: '10px' }}>
                              {formatAddress(
                                fcaParty.party.address_one,
                                fcaParty.party.city,
                                fcaParty.party.state,
                              )}
                            </td>
                            <td className="text-end">Fax:</td>
                            <td>{fcaParty.party.fax}</td>
                          </tr>,
                          <tr key={`{fcaParty.id}-{2}`}>
                            <td></td>
                            <td style={{ paddingLeft: '10px', maxWidth: '45rem' }}>
                              {formatAddress(fcaParty.party.address_two, fcaParty.party.zip_code)}
                            </td>
                            <td className="text-end">Email:</td>
                            <td>{fcaParty.party.email}</td>
                          </tr>,
                          <tr key={`{fcaParty.id}-{3}`}>
                            <td>&nbsp;</td>
                            <td></td>
                            <td className="text-end"></td>
                            <td></td>
                          </tr>,
                        ]
                      })}
                  </tbody>
                </table>
                {response?.rows?.length === 0 && <h6 className="text-center">No Data</h6>}
                <div
                  className="table table-responsive modal-content"
                  style={{ display: response?.rows?.length === 0 && 'none' }}
                >
                  <table className="table-hover align-middle mb-0 new-table">
                    <CTableHead className="align-middle">
                      <CTableRow>
                        <CTableHeaderCell scope="col">Check #</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Date</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Party</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Deposit</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Voucher</CTableHeaderCell>
                        <CTableHeaderCell scope="col">Balance</CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {response?.rows.map((row) => {
                        sVoucher += +row.voucher
                        sDeposit += +row.deposit
                        lastBalance = row.balance

                        return (
                          <CTableRow key={row.code}>
                            <CTableDataCell>{row.document_number}</CTableDataCell>
                            <CTableDataCell>
                              {row.entry_date ? moment(row.entry_date).format('MM/DD/YYYY') : ''}
                            </CTableDataCell>
                            <CTableDataCell>{row.party_name}</CTableDataCell>
                            <CTableDataCell>{formatCurrency(row.deposit)}</CTableDataCell>
                            <CTableDataCell>{formatCurrency(row.voucher)}</CTableDataCell>
                            <CTableDataCell>{formatCurrency(row.balance)}</CTableDataCell>
                          </CTableRow>
                        )
                      })}
                    </CTableBody>
                    <CTableFoot>
                      <CTableRow>
                        <CTableHeaderCell style={{ width: '270px' }}>Total:</CTableHeaderCell>
                        <CTableHeaderCell>{response?.rows.length}</CTableHeaderCell>
                        <CTableHeaderCell></CTableHeaderCell>
                        <CTableHeaderCell>{formatCurrency(sDeposit)}</CTableHeaderCell>
                        <CTableHeaderCell>{formatCurrency(sVoucher)}</CTableHeaderCell>
                        <CTableHeaderCell>{formatCurrency(lastBalance)}</CTableHeaderCell>
                      </CTableRow>
                    </CTableFoot>
                  </table>
                </div>
              </CCardBody>
            </CCard>
          </CContainer>
        </div>
        {!fca && <AppFooter />}
      </div>
    </div>
  )
}

export default FcaBalanceSheet
