import { Suspense, useEffect, useState } from 'react';
import { listFcaAdjustments } from 'src/services/FcaInvestmentAdjustmentService';
import AddFcaAdjustment from './AddAdjustment';
import ViewFcaAdjustment from './ViewAdjustment';

function FcaAdjustment({ fundsControlAccountId, fcaInvestmentId, refetchFCA,fetchInvestments }) {
    const [fcaAdjustments, setFcaAdjustments] = useState([]);

    useEffect(() => {
        listFcaAdjustments(fundsControlAccountId, fcaInvestmentId).then(response => {
            setFcaAdjustments(response.data.fca_investment_adjustments);
        })
    }, [fundsControlAccountId, fcaInvestmentId]);

    return (
        <>
            <Suspense fallback={<div>Loading Component</div>}>
                <table className="table table-sm table-striped bordered hover">
                    <thead>
                        <tr>
                            <th>Notes</th>
                            {/* <th>Prepaid Amount</th> */}
                            <th>Amount</th>
                            <th className="text-end">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <AddFcaAdjustment
                            fetchInvestments={fetchInvestments}
                            refetchFCA={refetchFCA}
                            fcaInvestmentId={fcaInvestmentId}
                            fundsControlAccountId={fundsControlAccountId}
                            pushFcaAdjustment={(pBL) => { setFcaAdjustments([...fcaAdjustments, pBL]) }}
                        />
                        {fcaAdjustments.map(fcaAdjustment =>
                            <ViewFcaAdjustment
                                fetchInvestments={fetchInvestments}
                                refetchFCA={refetchFCA}
                                key={fcaAdjustment.id}
                                fundsControlAccountId={fundsControlAccountId}
                                fcaAdjustments={fcaAdjustments}
                                singlefcaAdjustment={fcaAdjustment}
                                removeFcaAdjustment={(pBLId) => { setFcaAdjustments(fcaAdjustments.filter(pBL => pBLId !== pBL.id)) }}
                                replaceFcaAdjustment={(pBLId, pBL) => { setFcaAdjustments(fcaAdjustments.map(a => a.id === pBLId ? pBL : a)) }}
                            />
                        )}
                    </tbody>
                </table>
            </Suspense>
        </>
    )
}

export default FcaAdjustment
