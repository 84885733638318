import classNames from 'classnames';
import React, { Component } from 'react';

import { CTooltip } from '@coreui/react';
import { searchFcaParty } from '../services/FcaPartyService';
import AsyncCreatableSelect from 'react-select/async-creatable'
import { CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react'
import AddFcaParty from 'src/components/fundsControl/party/AddParty';
import AddParty from 'src/components/party/AddParty';

const customControlStyles = base => ({
    height: 30,
    minHeight: 30,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ccc'
});

class FcaPartySelect extends Component {

    constructor(props) {
        super(props)
        this.searchSelectRef = React.createRef();
        this.searchFcaParty = this.searchFcaParty.bind(this);
        this.state = {
          loadedOptions: [],
          showModal: false,
          unlistedOption: null,
        }
    }

    componentDidMount() {
        if (this.props.autoFocus) this.searchSelectRef.current.focus();
        this.fetchInitialOptions();
    }

    fetchInitialOptions = () => {
      this.searchFcaParty('', (options) => {
        this.setState({ loadedOptions: options });
      });
    };

    componentDidUpdate(prevProps) {
      if (prevProps.fundsControlAccountId !== this.props.fundsControlAccountId) {
        this.fetchInitialOptions();
      }
    }
    searchFcaParty(keyword, callback) {
      searchFcaParty(keyword, this.props.fundsControlAccountId)
            .then((response) => {
                let fca_parties = response.data.fca_parties;
                let options = fca_parties.map(party => {
                    return {
                        ...party,
                        value: party.id,
                        label: `[${party.type}] ${party.party.name}`,
                        license_expiry_date: party?.party?.license_expiry_date,
                        isDisabled: party.is_enabled !== 1
                    };
                });
                // if (this.state.loadedOptions.length === 0) {
                //   this.setState({ loadedOptions: options })
                // }
                callback(options);
            });
    }

    closeModal = () => {
      this.setState({ showModal: false }, this.fetchInitialOptions);
    };

    handleSelectChange = (fcaParty, action) => {
      const { loadedOptions } = this.state
      const selectedOptionExists = loadedOptions.some(
        (option) => option.value === fcaParty.value,
      )
      console.log(selectedOptionExists,fcaParty,loadedOptions,fcaParty.value)
      if (!selectedOptionExists) {
        this.setState({ showModal: true, unlistedOption: fcaParty })
      }
      this.props.onChange(fcaParty)
    }

    response = (data) => {
      this.searchFcaParty(data.name, (options) => {
        const selectedOption = options.find(option => option.label.includes(data.name));

        if (selectedOption) {
            this.props.onChange(selectedOption);
        }
      });
    }

    setUnlistedOption = (unlistedOption) => {
      this.setState({ unlistedOption })
    }

    render() {
        let sVal = null;
        if (this.props.value !== undefined) {
            sVal = this.props.value;
        }

        let a = <div style={{ display: 'block', width: this.props.width }}>
            <AsyncCreatableSelect
                cacheOptions={this.props.cacheOptions ?? true}
                defaultOptions={this.state.loadedOptions}
                components={{
                    IndicatorSeparator: () => null
                }}
                maxMenuHeight={150}
                ref={this.searchSelectRef}
                onChange={this.handleSelectChange}
                isSearchable={true}
                placeholder={this.props.placeholder}
                loadOptions={this.searchFcaParty}
                noOptionsMessage={() => { return "Type to search" }}
                value={sVal}
                isClearable={this.props.isClearable}
                aria-invalid={this.props.invalid ? 'true' : 'false'}
                name={this.props.name}
                className={classNames({
                    'is-invalid': this.props.invalid,
                    'is-valid': this.props.valid
                })}
                classNames={{
                    control: (state) => 'react-select-form-control',
                }}
                styles={{
                    control: customControlStyles,
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                onBlur={() => this.props.onBlur({ target: { name: this.props.name, value: sVal } })}
                isDisabled={this.props.disabled}
            />
        </div>;

        if (sVal?.label) a = <CTooltip content={sVal?.label} trigger={['hover', 'focus']} placement="top">
            {a}
        </CTooltip>

        return (
            <div>
              {this.props.label && <label className='form-label'>{this.props.label}</label>}
              {a}
              <CModal
                backdrop="static"
                visible={this.state.showModal}
                onClose={()=>this.closeModal()}
                className="form_modal file top"
              >
                <CModalHeader>
                  <CModalTitle id="LiveDemoExampleLabel">Create Party</CModalTitle>
                </CModalHeader>
                <CModalBody>
                  <AddParty
                    fundsControlAccountId={this.props.fundsControlAccountId}
                    name={this.state.unlistedOption}
                    setShowEditPopup={this.closeModal}
                    pushFcaParty={this.response}
                    type={this.props.type}
                    setUnlistedOption={this.setUnlistedOption}
                    createFcaParty={true}
                  />
                </CModalBody>
              </CModal>
            </div>
        )
    }
}

FcaPartySelect.defaultProps = {
    autoFocus: false,
    onBlur: () => { }
};

export default FcaPartySelect;


{/*
Usage:
    <FcaPartySelect
        callback={(r) => {
            setFieldValue('party_id', r.value);
            setParty(r)
        }}
        value={party}
        invalid={(errors.party_id && touched.party_id) || !!status?.apiErrors?.party_id}
        name="party_id"
        valid={(!errors.party_id && touched.party_id) && !status?.apiErrors?.party_id}
        onBlur={handleBlur}
    />
*/}
