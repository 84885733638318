import { cilApplications } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import {
  CButton,
  CButtonGroup,
  CFormFeedback,
  CFormInput,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CTooltip,
} from '@coreui/react'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { formatCurrency } from 'src/currencyFormatter'
import { laravelValidate } from 'src/laravelCompatibleValidator'
import { deleteFcaInvestment, updateFcaInvestment } from 'src/services/FcaInvestmentService'
import FcaPartySelect from 'src/utils/FcaPartySelect'
import { handleApiErrors } from 'src/utils/HandleErrors'
import { DeleteIconButton, EditIconButton } from 'src/utils/ImageIcons'
import { CFormInputWithMask } from 'src/utils/IMaskValidator'
import Swal from 'sweetalert2'
import FcaAdjustment from './investmentAdjustment'
import {
  SERVICE_TYPE_FUND_CONTROL_CHECKS,
  SERVICE_TYPE_FUND_CONTROL_NON_CHECKS,
} from 'src/utils/LmfceConstants'

const validator = (values) => {
  try {
    return laravelValidate(values, {
      // 'funds_control_account_id' : 'required|numeric|exists:funds_control_accounts,id',
      fca_party_id: 'required|numeric|exists:fca_parties,id',
      ending_date: 'nullable|date',
      document_number: 'nullable|string',
      description: 'nullable|string',
      starting_prepaid_amount: 'required|numeric',
      starting_investment_amount: 'required|numeric',
    })
  } catch (error) {
    console.error(error)
    return {}
  }
}

function ViewFcaInvestment({
  singlefcaInvestment,
  removeFcaInvestment,
  replaceFcaInvestment,
  fundsControlAccountId,
  refetchFCA,
  fetchInvestments,
  updateRecords = true,
  serviceType,
}) {
  const [party, setParty] = useState(undefined)
  const [visible, setVisible] = useState(false)
  const [investmentId, setInvestmentId] = useState('')
  const [amount, setAmount] = useState(singlefcaInvestment?.starting_investment_amount)

  useEffect(() => {
    setParty({
      value: singlefcaInvestment?.fca_party?.party?.id,
      label: singlefcaInvestment?.fca_party?.party?.name,
    })
  }, [singlefcaInvestment])

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete <code>${singlefcaInvestment?.fca_party?.party?.name}</code>?`,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: 'Delete',
      confirmButtonColor: '#e55353',
      reverseButtons: true,
    })

    if (result.isConfirmed) {
      deleteFcaInvestment(singlefcaInvestment?.id).then(
        (response) => {
          removeFcaInvestment(singlefcaInvestment?.id)
          refetchFCA()
          toast.error('Deleted Successfully')
        },
        (err) => {
          handleApiErrors(err)
        },
      )
    }
  }

  const handleSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    setSubmitting(true)
    updateFcaInvestment(singlefcaInvestment?.id, values).then(
      (response) => {
        replaceFcaInvestment(singlefcaInvestment?.id, response.data.fca_investment)
        refetchFCA()
        toast.success('Edited Successfully')
        setSubmitting(false)
        resetForm()
      },
      (err) => {
        setSubmitting(false)
        handleApiErrors(err)
        setStatus({ apiErrors: err.response.data.errors })
      },
    )
  }

  const initialFormValues = singlefcaInvestment

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount={true}
        initialValues={initialFormValues}
        validate={validator}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          status,
          setFieldValue,
        }) => (
          <tr>
            {/* {updateRecords &&
            <td>
              <FcaPartySelect
                fundsControlAccountId={fundsControlAccountId}
                placeholder="Select Party"
                name="fca_party_id"
                className="select-custom-class"
                hover={hover}
                onChange={(party) => {
                  setFieldValue('fca_party_id', party.value)
                  setParty(party)
                }}
                value={party}
                onBlur={handleBlur}
                valid={isSubmitting}
                invalid={(errors.fca_party_id) || !!status?.apiErrors?.fca_party_id}
              >
              </FcaPartySelect>
              <CFormFeedback className='invalid-feedback'>{errors.fca_party_id || status?.apiErrors?.fca_party_id?.join('|')}</CFormFeedback>
            </td>
            } */}
            <td style={{ verticalAlign: 'middle' }}>
              <label className="form-label" style={{ color: '#909090', fontWeight: 'bold' }}>
                {values.fca_party.type}
              </label>
            </td>
            {/* {updateRecords &&
            <td>
              <CFormInput
                type="string"
                className='form-control-sm'
                placeholder="Doc. #"
                name="document_number"
                id="document_number"
                onChange={handleChange}
                onBlur={handleBlur}
                disabled={!updateRecords}
                value={values.document_number}
                valid={isSubmitting}
                invalid={(errors.document_number && touched.document_number) || !!status?.apiErrors?.document_number}
              />
              <CFormFeedback className='invalid-feedback'>{errors.document_number || status?.apiErrors?.document_number?.join('|')}</CFormFeedback>
            </td>
            } */}
            <td>
              <CFormInputWithMask
                mask="$num"
                blocks={{
                  num: {
                    mask: Number,
                    signed: true,
                    scale: 2, // digits after the decimal point
                    thousandsSeparator: ',', // thousands separator
                    padFractionalZeros: false, // prevent padding zeros
                    normalizeZeros: false, // avoid normalizing zeros
                    radix: '.', // decimal point delimiter
                  },
                }}
                onAccept={(value, mask) => {
                  setAmount(mask._rawInputValue)
                }}
                disabled={!updateRecords}
                className="form-control-sm"
                placeholder="Org. Amount"
                name="starting_investment_amount"
                id="starting_investment_amount"
                onFocus={(e) => e.target.select()}
                onBlur={(e) => {
                  handleBlur(e);
                  const handleAmountChange = () => {
                    updateFcaInvestment(singlefcaInvestment?.id, { ...values,starting_investment_amount: amount }).then((response) => {
                      toast.success('Amount updated successfully');
                    }).catch((err) => {
                      toast.error('Failed to update amount');
                    });
                  };
                  handleAmountChange();
                }}
                value={amount.toString()}
              />
            </td>
            <td>
              <CFormInputWithMask
                readOnly
                mask="$num"
                blocks={{
                  num: {
                    // nested masks are available!
                    mask: Number,
                    thousandsSeparator: ',',
                    radix: '.',
                  },
                }}
                disabled={!updateRecords}
                className="form-control-sm"
                value={formatCurrency(
                  (singlefcaInvestment.current_investment_amount || 0) -
                    (singlefcaInvestment.starting_investment_amount || 0),
                ).toString()}
              />
            </td>
            <td>
              <CFormInputWithMask
                readOnly
                mask="$num"
                blocks={{
                  num: {
                    // nested masks are available!
                    mask: Number,
                    thousandsSeparator: ',',
                    radix: '.',
                  },
                }}
                disabled={!updateRecords}
                className="form-control-sm"
                value={formatCurrency(values.current_investment_amount || 0).toString()}
              />
            </td>
            <td>
              <CFormInputWithMask
                readOnly
                mask="$num"
                blocks={{
                  num: {
                    // nested masks are available!
                    mask: Number,
                    thousandsSeparator: ',',
                    radix: '.',
                  },
                }}
                disabled={!updateRecords}
                className="form-control-sm"
                value={formatCurrency(values.in_process_budget || 0).toString()}
              />
            </td>
            <td>
              <CFormInputWithMask
                readOnly
                mask="$num"
                blocks={{
                  num: {
                    // nested masks are available!
                    mask: Number,
                    thousandsSeparator: ',',
                    radix: '.',
                  },
                }}
                disabled={!updateRecords}
                className="form-control-sm"
                value={formatCurrency(values.spent_budget || 0).toString()}
              />
            </td>
            <td>
              <CFormInputWithMask
                readOnly
                mask="$num"
                blocks={{
                  num: {
                    // nested masks are available!
                    mask: Number,
                    thousandsSeparator: ',',
                    radix: '.',
                  },
                }}
                disabled={!updateRecords}
                className="form-control-sm"
                value={formatCurrency(
                  (values.current_investment_amount || 0) - (values.spent_budget || 0),
                ).toString()}
              />
            </td>
            {updateRecords && (
              <td className="text-end align-middle">
                <CButtonGroup>
                  {/* <EditIconButton
                    onClick={handleSubmit}
                    stylesheet={{
                      background: '#D2E0DD',
                      border: 'none',
                      marginRight: '10px',
                      borderRadius: '8px',
                    }}
                    imgStyles={{ width: '1.2rem', height: '1.2rem' }}
                    data=""
                    isDisabled={isSubmitting}
                  /> */}
                  {/* {serviceType === SERVICE_TYPE_FUND_CONTROL_NON_CHECKS && (
                    <DeleteIconButton
                      onClick={handleDelete}
                      stylesheet={{
                        background: 'rgba(195, 63, 17, 0.1)',
                        border: 'none',
                        marginRight: '10px',
                        borderRadius: '8px',
                      }}
                      imgStyles={{ width: '1.2rem', height: '1.2rem' }}
                      data=""
                      isDisabled={isSubmitting}
                    />
                  )} */}
                  <CTooltip content="Add Funds Adjustment" placement="top">
                    <CButton
                      color="success"
                      className="px-2 btn-sm"
                      onClick={() => {
                        setVisible(!visible)
                        setInvestmentId(values.id)
                      }}
                      disabled={isSubmitting}
                    >
                      <CIcon icon={cilApplications} />
                    </CButton>
                  </CTooltip>
                </CButtonGroup>
              </td>
            )}
          </tr>
        )}
      </Formik>
      <CModal
        visible={visible}
        onClose={() => setVisible(false)}
        aria-labelledby="LiveDemoExampleLabel"
        className={`form_modal top`}
        backdrop="static"
      >
        <CModalHeader onClose={() => setVisible(false)}>
          <CModalTitle id="LiveDemoExampleLabel">Funds Adjustment</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <FcaAdjustment
            fetchInvestments={fetchInvestments}
            refetchFCA={refetchFCA}
            fcaPartyId={singlefcaInvestment.fca_party_id}
            fundsControlAccountId={singlefcaInvestment.funds_control_account_id}
            fcaInvestmentId={investmentId}
          />
        </CModalBody>
      </CModal>
    </>
  )
}

export default ViewFcaInvestment
