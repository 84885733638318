import { cilDelete, cilSave } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CButtonGroup, CFormFeedback, CFormInput, CSpinner } from '@coreui/react';
import { Formik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { laravelValidate } from 'src/laravelCompatibleValidator';
import { deleteFcaAdjustment, updateFcaAdjustment } from 'src/services/FcaInvestmentAdjustmentService';
import { handleApiErrors } from 'src/utils/HandleErrors';
import { CFormInputWithMask } from 'src/utils/IMaskValidator';
import { DeleteIconButton, EditIconButton } from 'src/utils/ImageIcons';
import Swal from 'sweetalert2';

const validator = values => {
  try {
    return laravelValidate(values, {
      'fca_investment_id': 'required|numeric|exists:fca_investments,id',
      'notes': 'nullable|string',
      'prepaid_amount': 'required|numeric',
      'investment_amount': 'required|numeric',
    });
  } catch (error) {
    console.error(error);
    return {};
  }
}

function ViewFcaAdjustment({ singlefcaAdjustment, removeFcaAdjustment, replaceFcaAdjustment,refetchFCA,fetchInvestments }) {
  const [isBusy, setIsBusy] = useState(false)

  const handleDelete = async () => {
    const result = await Swal.fire({
      title: `Are you sure you want to delete <code>${singlefcaAdjustment.notes}</code>?`,
      showCancelButton: true,
      icon: 'warning',
      confirmButtonText: "Delete",
      confirmButtonColor: '#e55353',
      reverseButtons: true
    });

    if (result.isConfirmed) {
      setIsBusy(true)
      deleteFcaAdjustment(singlefcaAdjustment.id).then(response => {
        setIsBusy(false)
        removeFcaAdjustment(singlefcaAdjustment.id);
        refetchFCA()
        fetchInvestments()
        toast.error('Deleted Successfully')
      },
        err => {
          setIsBusy(false)
          handleApiErrors(err);
        })
    }
  }

  const handleSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    setSubmitting(true);
    updateFcaAdjustment(singlefcaAdjustment.id, values).then(response => {
      replaceFcaAdjustment(singlefcaAdjustment.id, response.data.fca_investment_adjustment);
      refetchFCA()
      fetchInvestments()
      toast.success('Edited Successfully')
      setSubmitting(false);
      resetForm();
    },
      err => {
        setSubmitting(false);
        handleApiErrors(err);
        setStatus({ apiErrors: err.response.data.errors })
      })
  };

  const initialFormValues = singlefcaAdjustment;

  return (
    <>
      <Formik
        enableReinitialize
        validateOnMount={true}
        initialValues={initialFormValues}
        validate={validator}
        onSubmit={handleSubmit}
      >
        {({
          values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
          status, setFieldValue
        }) => (
          <tr>
            <td>
              <CFormInput
                name="notes"
                className='form-control-sm'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.notes}
                valid={isSubmitting}
                invalid={(errors.notes) || !!status?.apiErrors?.notes}
                placeholder='Select Notes'
              />
              <CFormFeedback className='invalid-feedback'>{errors.notes || status?.apiErrors?.notes?.join('|')}</CFormFeedback>
            </td>
            {/* <td>
            <CFormInputWithMask
              mask="$num"
              blocks={{
                num: {
                  // nested masks are available!
                  mask: Number,
                  thousandsSeparator: ',',
                  radix: "."
                }
              }
              }
              onAccept={
                (value, mask) => { { setFieldValue('prepaid_amount', mask._unmaskedValue); } }
              }
              className='form-control-sm'
              placeholder="Prepaid Amount"
              name="prepaid_amount"
              id="prepaid_amount"
              // onChange={handleChange}
              onBlur={handleBlur}
              value={values.prepaid_amount}
              valid={isSubmitting}
              invalid={(errors.prepaid_amount && touched.prepaid_amount) || !!status?.apiErrors?.prepaid_amount}
            />
              <CFormFeedback className='invalid-feedback'>{errors.prepaid_amount || status?.apiErrors?.prepaid_amount?.join('|')}</CFormFeedback>
            </td> */}
            <td>
            <CFormInputWithMask
              mask="$num"
              blocks={{
                num: {
                  // nested masks are available!
                  mask: Number,
                  thousandsSeparator: ',',
                  radix: ".",
                  max: 99999999,
                }
              }
              }
              onAccept={
                (value, mask) => { { setFieldValue('investment_amount', mask._unmaskedValue); } }
              }
              className='form-control-sm'
              placeholder="Funds Amount"
              name="investment_amount"
              id="investment_amount"
              // onChange={handleChange}
              onBlur={handleBlur}
              value={values.investment_amount}
              valid={isSubmitting}
              invalid={(errors.investment_amount && touched.investment_amount) || !!status?.apiErrors?.investment_amount}
            />
              <CFormFeedback className='invalid-feedback'>{errors.investment_amount || status?.apiErrors?.investment_amount?.join('|')}</CFormFeedback>
            </td>
            <td className="text-end">
              <CButtonGroup>
                <EditIconButton onClick={handleSubmit} stylesheet={{ background: '#D2E0DD', border: 'none', marginRight: '10px', borderRadius: '8px' }} imgStyles={{ width: '1.2rem', height: '1.2rem' }} data="" isDisabled={isSubmitting} />
                <DeleteIconButton onClick={handleDelete} stylesheet={{ background: 'rgba(195, 63, 17, 0.1)', border: 'none', marginRight: '10px', borderRadius: '8px' }} imgStyles={{ width: '1.2rem', height: '1.2rem' }} data="" isDisabled={isSubmitting} />
              </CButtonGroup>
            </td>
          </tr>
        )}
      </Formik>
    </>
  )
}

export default ViewFcaAdjustment
