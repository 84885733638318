import { CButton, CCard, CCardBody, CCol, CForm, CFormFeedback, CFormInput, CFormSelect, CRow, CSpinner } from '@coreui/react';
import { Formik } from 'formik';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { COUNTRY_UNITED_STATES } from 'src/constants/countries';
import { laravelValidate } from 'src/laravelCompatibleValidator';
import { storeFcaParty } from 'src/services/FcaPartyService';
import { storeParty } from 'src/services/PartyService';
import { handleApiErrors } from 'src/utils/HandleErrors';
import { CFormInputWithMask } from 'src/utils/IMaskValidator';
import { partiesTypes, POSSIBLE_BANK_ACCOUNT_FOR, POSSIBLE_BANK_ACCOUNT_TYPES } from 'src/utils/LmfceConstants';
import StateSelect from 'src/utils/StateSelect';

const validator = values => {
  try {
    return laravelValidate(values, {
      'type': 'required|string|max:40|in:' + partiesTypes.join(','),
      'name': 'required|string|max:50|unique:parties,name',
      'contact_person': 'nullable|string|max:50',
      'address_one': 'nullable|string|max:255',
      'address_two': 'nullable|string|max:255',
      'city': 'nullable|string|max:50',
      'state': 'nullable|string|max:50',
      'zip_code': 'nullable|string|max:10',
      'country': 'nullable|string|max:50',
      'email': 'nullable|string|max:50',
      'website': 'nullable|string|max:50',
      'telephone_one': 'nullable|string|max:50',
      'telephone_two': 'nullable|string|max:50',
      'fax': 'nullable|string|max:50',
      'memorandum': 'nullable|string',
      'irs_tax_id': 'nullable|string',
      'social_security_number': 'nullable|numeric|digits:9',
      'w_nine_form_file': 'nullable|file|mimetypes:audio/*,image/*,text/*,video/*',
      'ach_onboarding_form_file': 'nullable|file|mimetypes:audio/*,image/*,text/*,video/*',
      'is_active': 'nullable',
      'bank_code' : ['nullable', 'string', 'max:100'],
      'bank_account_number' : ['nullable', 'string', 'max:100'],
      'bank_account_type' : ['nullable', 'string'],
      'bank_account_for' : ['nullable', 'string'],
    });
  } catch (error) {
    console.error(error);
    return {};
  }
}

function AddParty({ setShowEditPopup, pushParty, pushFcaParty, name, type, setUnlistedOption, fundsControlAccountId, createFcaParty = false }) {
  const [country, setCountry] = useState(undefined)
  const [state, setState] = useState(undefined)

  const handleSubmit = async (values, { setStatus, setSubmitting, resetForm }) => {
    setSubmitting(true);
    try {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });

      const response = await storeParty(formData);

      if (typeof pushParty === 'function') {
        pushParty(response.data.party);
      }

      if (createFcaParty) {
        const { party } = response.data;
        let data = new FormData();
        data.append('funds_control_account_id', fundsControlAccountId);
        data.append('party_id', party.id);
        data.append('type', party.type);
        data.append('is_enabled', true);

        const fcaResponse = await storeFcaParty(data);

        if (typeof pushFcaParty === 'function') {
          pushFcaParty(fcaResponse.data.fca_party.party);
        }
      }

      setSubmitting(false);
      setShowEditPopup(false);
      toast.success('Party added successfully');
    } catch (error) {
      handleApiErrors(error);
      setSubmitting(false);
      setStatus({ apiErrors: error.response.data.errors });
      console.error('Error:', error);
    }
  };


  return (
    <CCard>
      <Formik
        initialValues={{ name: name?.value || '', type: type || '', country: COUNTRY_UNITED_STATES, zip_code: '', telephone_one: '', telephone_two: '', fax: '', is_active: 1 }}
        validate={validator}
        onSubmit={handleSubmit}
      >
        {({
          values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
          status, setFieldValue
        }) => (
          <CForm type="submit" onSubmit={handleSubmit}>
            <CCardBody>
              <CRow className="mt-1">
                <CCol>
                  <label className="form-label">Type <span className="text-danger">*</span></label>
                  <CFormSelect
                    name="type"
                    className={`form-control form-control-sm ${isSubmitting ? 'is-valid' : ''}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!!type}
                    {...(type ? { value: type } : {})}
                  >
                    <option value="">Please Select Type</option>
                    {partiesTypes.map((o, i) => <option value={o} key={o}>{o}</option>)}
                  </CFormSelect>
                  <CFormFeedback className='invalid-feedback'>{errors.type || status?.apiErrors?.type?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <label className="form-label" >Name <span className="text-danger">*</span></label>
                  <CFormInput
                    className='form-control-sm'
                    placeholder="Name"
                    name="name"
                    id="name"
                    // disabled={!!name}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const isValid = /^[^.,]*$/.test(inputValue);
                      if (isValid) {
                        handleChange(e);
                        if (typeof setUnlistedOption === "function") {
                          setUnlistedOption(inputValue);
                        }
                      } else {
                        toast.error("Invalid name.");
                      }
                    }}
                    onBlur={handleBlur}
                    value={values.name}
                    valid={isSubmitting}
                    invalid={(errors.name && touched.name) || !!status?.apiErrors?.name}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.name || status?.apiErrors?.name?.join('|')}</CFormFeedback>
                </CCol>
              </CRow>
              <CRow className="mt-1">
                <CCol>
                  <CFormInput
                    label="Contact Person"
                    placeholder="Contact person"
                    name="contact_person"
                    id="contact_person"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contact_person}
                    valid={isSubmitting}
                    invalid={(errors.contact_person && touched.contact_person) || !!status?.apiErrors?.contact_person}
                    className='form-control-sm me-3'
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.contact_person || status?.apiErrors?.contact_person?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <label>Status</label>
                  <CFormSelect
                    name="is_active"
                    className={`form-control form-control-sm ${isSubmitting ? 'is-valid' : ''}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.is_active}
                  >
                    {['1', '0'].map((f, i) => (
                      <option value={f} key={i}>
                        {f === '0' ? 'Inactive' : 'Active'}
                      </option>
                    ))}
                  </CFormSelect>
                  <CFormFeedback className='invalid-feedback'>{errors.is_active || status?.apiErrors?.is_active?.join('|')}</CFormFeedback>
                </CCol>
              </CRow>
              <CRow>
                {/* <CCol>
                  <CFormInput
                    label="Address Two"
                    placeholder="Address Two"
                    name="address_two"
                    className='form-control-sm me-3'
                    id="address_two"
                    onChange={(e) => { handleChange(e); setParty({ ...party, address_two: e.target.value }); }}
                    onBlur={handleBlur}
                    value={values.address_two}
                    valid={isSubmitting}
                    invalid={(errors.address_two && touched.address_two) || !!status?.apiErrors?.address_two}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.address_two || status?.apiErrors?.address_two?.join('|')}</CFormFeedback>
                </CCol> */}
                {/* <CCol>
                  <CFormInputWithMask
                    mask={Number}
                    min={1}
                    max={999999999}
                    label="SSN"
                    placeholder="SSN"
                    name="social_security_number"
                    className='form-control-sm me-3'
                    id="social_security_number"
                    onChange={(e) => { handleChange(e); setParty({ ...party, social_security_number: e.target.value }); }}
                    onBlur={handleBlur}
                    value={values.social_security_number}
                    valid={isSubmitting}
                    invalid={(errors.social_security_number && touched.social_security_number) || !!status?.apiErrors?.social_security_number}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.social_security_number || status?.apiErrors?.social_security_number?.join('|')}</CFormFeedback>
                </CCol> */}
                <CCol>
                  <CFormInput
                    label="Address"
                    className='form-control-sm'
                    placeholder="Address"
                    name="address_one"
                    id="address_one"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address_one}
                    valid={isSubmitting}
                    invalid={(errors.address_one && touched.address_one) || !!status?.apiErrors?.address_one}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.address_one || status?.apiErrors?.address_one?.join('|')}</CFormFeedback>
                </CCol>
              </CRow>

              <CRow className="mt-1">
                {/* <CCol>
                  <label className='form-label'>Country</label>
                  <CountrySelect
                    placeholder="Select Country"
                    name="country"
                    className="select-custom-class"
                    onChange={(party) => {
                      setFieldValue('country', party.value)
                      setCountry(party)
                    }}
                    value={country}
                    onBlur={handleBlur}
                    valid={isSubmitting}
                    invalid={(errors.country && touched.country) || !!status?.apiErrors?.country}
                  >
                  </CountrySelect>
                  <CFormFeedback className='invalid-feedback'>{errors.country || status?.apiErrors?.country?.join('|')}</CFormFeedback>
                </CCol> */}
                <CCol md={6}>
                  <CFormInput
                    label="Email"
                    className='form-control-sm'
                    type='email'
                    placeholder="Email"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    valid={isSubmitting}
                    invalid={(errors.email && touched.email) || !!status?.apiErrors?.email}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.email || status?.apiErrors?.email?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <CFormInput
                    label="City"
                    className='form-control-sm'
                    placeholder="City"
                    name="city"
                    id="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    valid={isSubmitting}
                    invalid={(errors.city && touched.city) || !!status?.apiErrors?.city}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.city || status?.apiErrors?.city?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <label className='form-label'>State</label>
                  {values.country === COUNTRY_UNITED_STATES
                    ?
                    <StateSelect
                      placeholder="State"
                      name="state"
                      className="select-custom-class"
                      onChange={(party) => {
                        setFieldValue('state', party.value)
                        setState(party)
                      }}
                      value={state}
                      onBlur={handleBlur}
                      valid={isSubmitting}
                      invalid={(errors.state && touched.state) || !!status?.apiErrors?.state}
                    >
                    </StateSelect>
                    :
                    <CFormInput
                      placeholder="State"
                      name="state"
                      className='form-control-sm'
                      id="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.state}
                      valid={isSubmitting}
                      invalid={(errors.state && touched.state) || !!status?.apiErrors?.state}
                    />
                  }
                  <CFormFeedback className='invalid-feedback'>{errors.state || status?.apiErrors?.state?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <CFormInputWithMask
                    mask={Number}
                    min={1}
                    max={99999}
                    label="Zip code"
                    className='form-control-sm'
                    placeholder="Zip code"
                    name="zip_code"
                    id="zip_code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.zip_code}
                    valid={isSubmitting}
                    invalid={(errors.zip_code && touched.zip_code) || !!status?.apiErrors?.zip_code}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.zip_code || status?.apiErrors?.zip_code?.join('|')}</CFormFeedback>
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <CFormInput
                    label="Website"
                    placeholder="Website"
                    name="website"
                    className='form-control-sm me-3'
                    id="website"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.website}
                    valid={isSubmitting}
                    invalid={(errors.website && touched.website) || !!status?.apiErrors?.website}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.website || status?.apiErrors?.website?.join('|')}</CFormFeedback>
                </CCol>

                <CCol>
                  <CFormInputWithMask
                    mask='+{1} (000)000-0000'
                    label="Telephone one"
                    className='form-control-sm'
                    placeholder="Telephone one"
                    name="telephone_one"
                    id="telephone_one"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.telephone_one}
                    valid={isSubmitting}
                    invalid={(errors.telephone_one && touched.telephone_one) || !!status?.apiErrors?.telephone_one}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.telephone_one || status?.apiErrors?.telephone_one?.join('|')}</CFormFeedback>
                </CCol>
              </CRow>
              <CRow className="mt-1">
                <CCol>
                  <CFormInputWithMask
                    mask='+{1} (000)000-0000'
                    label="Telephone two"
                    placeholder="Telephone two"
                    name="telephone_two"
                    className='form-control-sm me-3'
                    id="telephone_two"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.telephone_two}
                    valid={isSubmitting}
                    invalid={(errors.telephone_two && touched.telephone_two) || !!status?.apiErrors?.telephone_two}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.telephone_two || status?.apiErrors?.telephone_two?.join('|')}</CFormFeedback>
                </CCol>
                {/* <CCol>
                  <CFormInputWithMask
                    mask='+{1} (000)000-0000'
                    label="Fax"
                    className='form-control-sm'
                    placeholder="Fax"
                    name="fax"
                    id="fax"
                    onChange={(e) => { handleChange(e); setParty({ ...party, fax: e.target.value }); }}
                    onBlur={handleBlur}
                    value={values.fax}
                    valid={isSubmitting}
                    invalid={(errors.fax && touched.fax) || !!status?.apiErrors?.fax}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.fax || status?.apiErrors?.fax?.join('|')}</CFormFeedback>
                </CCol> */}
                <CCol>
                  <label className="form-label">IRS Tax ID/SSN</label>
                  <CFormSelect
                    name="irs_tax_id"
                    className={`form-control form-control-sm ${isSubmitting ? 'is-valid' : ''}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.irs_tax_id}
                  >
                    <option value="">Please Select</option>
                    {['IRS Tax ID', 'SSN'].map((f, i) => (
                      <option value={f} key={i}>
                        {f}
                      </option>
                    ))}
                  </CFormSelect>
                  <CFormFeedback className='invalid-feedback'>{errors.irs_tax_id || status?.apiErrors?.irs_tax_id?.join('|')}</CFormFeedback>
                </CCol>
              </CRow>

              <CRow>
                <CCol>
                  <label className='d-block mb-2'>W9 Form File</label>
                  <CFormInput
                    type="file"
                    name="w_nine_form_file"
                    id="w_nine_form_file"
                    onChange={(e) => { setFieldValue('w_nine_form_file', e.target.files[0]); }}
                    onBlur={handleBlur}
                    valid={isSubmitting}
                    invalid={(errors.w_nine_form_file && touched.w_nine_form_file) || !!status?.apiErrors?.w_nine_form_file}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.w_nine_form_file || status?.apiErrors?.w_nine_form_file?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <label className='d-block mb-2'>ACH Onboarding form file</label>
                  <CFormInput
                    type="file"
                    name="ach_onboarding_form_file"
                    id="ach_onboarding_form_file"
                    onChange={(e) => { setFieldValue('ach_onboarding_form_file', e.target.files[0]); }}
                    onBlur={handleBlur}
                    valid={isSubmitting}
                    invalid={(errors.ach_onboarding_form_file && touched.ach_onboarding_form_file) || !!status?.apiErrors?.ach_onboarding_form_file}
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.ach_onboarding_form_file || status?.apiErrors?.ach_onboarding_form_file?.join('|')}</CFormFeedback>
                </CCol>
              </CRow>
              <CRow className="mt-1">
                <CCol>
                  <CFormInput
                    label="Bank Code"
                    placeholder="Bank Code"
                    name="bank_code"
                    id="bank_code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bank_code}
                    valid={isSubmitting}
                    invalid={(errors.bank_code && touched.bank_code) || !!status?.apiErrors?.bank_code}
                    className='form-control-sm me-3'
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.bank_code || status?.apiErrors?.bank_code?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <CFormInput
                    label="Bank Account #"
                    placeholder="Bank Account #"
                    name="bank_account_number"
                    id="bank_account_number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bank_account_number}
                    valid={isSubmitting}
                    invalid={(errors.bank_account_number && touched.bank_account_number) || !!status?.apiErrors?.bank_account_number}
                    className='form-control-sm me-3'
                  />
                  <CFormFeedback className='invalid-feedback'>{errors.bank_account_number || status?.apiErrors?.bank_account_number?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <label className='form-label'>Bank Account Type</label>
                  <CFormSelect
                    name="bank_account_type"
                    className={`form-control form-control-sm ${isSubmitting ? 'is-valid' : ''}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bank_account_type}
                  >
                    <option value="">Please Select</option>
                    {POSSIBLE_BANK_ACCOUNT_TYPES.map((f, i) => (
                      <option value={f} key={i}>
                       {f}
                      </option>
                    ))}
                  </CFormSelect>
                  <CFormFeedback className='invalid-feedback'>{errors.bank_account_type || status?.apiErrors?.bank_account_type?.join('|')}</CFormFeedback>
                </CCol>
                <CCol>
                  <label className='form-label'>Bank Account for</label>
                  <CFormSelect
                    name="bank_account_for"
                    className={`form-control form-control-sm ${isSubmitting ? 'is-valid' : ''}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.bank_account_for}
                  >
                    <option value="">Please Select</option>
                    {POSSIBLE_BANK_ACCOUNT_FOR.map((f, i) => (
                      <option value={f} key={i}>
                        {f}
                      </option>
                    ))}
                  </CFormSelect>
                  <CFormFeedback className='invalid-feedback'>{errors.bank_account_for || status?.apiErrors?.bank_account_for?.join('|')}</CFormFeedback>
                </CCol>
              </CRow>
              <CRow className='mt-4 float-end'>
                <CButton color="primary" className='next' type='submit' disabled={isSubmitting}>
                  {isSubmitting && <CSpinner variant="grow" size="sm" />}
                  {' '}Add
                </CButton>
              </CRow>
            </CCardBody>
          </CForm>
        )}
      </Formik >
    </CCard >
  )
}

export default AddParty
