import { CFormInput } from '@coreui/react';
import { formatCurrency } from 'src/currencyFormatter';

const InvestmentSummaryTable = ({ data }) => {
  // Calculate totals
  const totalOrgAmount = data.reduce((acc, fcInvestment) => acc + Number(fcInvestment.starting_budget), 0);
  const totalChanges = data.reduce((acc, fcInvestment) => acc + Number(fcInvestment.current_budget - fcInvestment.starting_budget), 0);
  const totalCurrentAmount = data.reduce((acc, fcInvestment) => acc + Number(fcInvestment.current_budget), 0);
  const totalDisbursed = data.reduce((acc, fcInvestment) => acc + Number(fcInvestment.spent_budget), 0);
  const totalInProcess = data.reduce((acc, fcInvestment) => acc + Number(fcInvestment.in_process_budget), 0);
  const totalRemainingAmount = data.reduce((acc, fcInvestment) =>
    acc + Number(fcInvestment.current_budget - fcInvestment.in_process_budget - fcInvestment.spent_budget), 0);

  return (
    <table className="table table-sm hover mt-5 new-table">
      <thead>
        <tr>
          <th style={{ width: '10rem' }}></th>
          <th style={{ width: '14rem' }}></th>
          <th>Org. Amount</th>
          <th>Changes</th>
          <th>Current Amount</th>
          <th>Disbursed</th>
          <th>In Process</th>
          <th>Remaining Amount</th>
        </tr>
      </thead>
      <tbody>
        {data.map((fcInvestment, i) => (
          <tr key={i}>
            <td style={{ verticalAlign: 'middle' }}>
              <label className="form-label" style={{ color: '#909090', fontWeight: 'bold' }}>
                {fcInvestment.type} Cost
              </label>
            </td>
            <td></td>
            <td>
              <CFormInput
                readOnly
                className="form-control-sm"
                value={formatCurrency(fcInvestment.starting_budget)}
              />
            </td>
            <td>
              <CFormInput
                readOnly
                className="form-control-sm"
                value={formatCurrency(fcInvestment.current_budget - fcInvestment.starting_budget)}
              />
            </td>
            <td>
              <CFormInput
                readOnly
                className="form-control-sm"
                value={formatCurrency(fcInvestment.current_budget)}
              />
            </td>
            <td>
              <CFormInput
                readOnly
                className="form-control-sm"
                value={formatCurrency(fcInvestment.spent_budget)}
              />
            </td>
            <td>
              <CFormInput
                readOnly
                className="form-control-sm"
                value={formatCurrency(fcInvestment.in_process_budget)}
              />
            </td>
            <td>
              <CFormInput
                readOnly
                className="form-control-sm"
                value={formatCurrency(fcInvestment.current_budget - fcInvestment.in_process_budget - fcInvestment.spent_budget)}
              />
            </td>
          </tr>
        ))}
        {/* Total Row */}
        <tr>
          <td><strong>Total</strong></td>
          <td></td>
          <td>{formatCurrency(totalOrgAmount)}</td>
          <td>{formatCurrency(totalChanges)}</td>
          <td>{formatCurrency(totalCurrentAmount)}</td>
          <td>{formatCurrency(totalDisbursed)}</td>
          <td>{formatCurrency(totalInProcess)}</td>
          <td>{formatCurrency(totalRemainingAmount)}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default InvestmentSummaryTable;
