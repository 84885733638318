import { cilDataTransferUp } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CFormFeedback, CFormInput, CFormSelect, CFormSwitch, CImage } from '@coreui/react';
import { Formik } from 'formik';
import { useState } from 'react';

import { toast } from 'react-toastify';
import { laravelValidate } from 'src/laravelCompatibleValidator';
import { storeFcaParty } from 'src/services/FcaPartyService';
import { handleApiErrors } from 'src/utils/HandleErrors';
import { AddIconButton } from 'src/utils/ImageIcons';
import { lienWaiverTypes, partiesTypes } from 'src/utils/LmfceConstants';
import PartySelect from 'src/utils/PartySelect';
import upload from './../../../assets/images/buttons/upload.png';

const validator = values => {
  try {
    return laravelValidate(values, {
      'funds_control_account_id': 'required|numeric|exists:funds_control_accounts,id',
      'party_id': 'required|numeric|exists:parties,id',
      'type': 'required|string|max:40',
      'funding_priority': 'nullable|numeric',
      'is_enabled': 'nullable|numeric',
      'lien_waiver_form': 'nullable|file',
    });
  } catch (error) {
    console.error(error);
    return {};
  }
}

function AddFcaParty({ fundsControlAccountId, pushFcaParty,closeModal,setUnlistedOption }) {
  const [party, setParty] = useState(undefined);

  const handleSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    setSubmitting(true);
    let formData = new FormData();
    formData.append('funds_control_account_id', values.funds_control_account_id);
    formData.append('party_id', values.party_id);
    formData.append('type', values.type);
    formData.append('funding_priority', values.funding_priority);
    formData.append('lien_waiver_form', values.lien_waiver_form);
    formData.append('is_enabled', values.is_enabled);
    formData.append('lien_waiver_type', values.lien_waiver_type);

    storeFcaParty(formData).then(response => {
      pushFcaParty(response.data.fca_party);
      setSubmitting(false);
      toast.success('FC Party added successfully!');
      setParty(undefined);
      if (typeof closeModal === 'function') {
        closeModal()
      }
      resetForm();
    },
      err => {
        setSubmitting(false);
        handleApiErrors(err);
        setStatus({ apiErrors: err.response.data.errors })
      });
  }

  return (
    <Formik
      initialValues={{
        'funds_control_account_id': fundsControlAccountId,
        'party_id': '',
        'type': '',
        'funding_priority': 1,
        'is_enabled': '',
        'lien_waiver_form': '',
        'lien_waiver_type': ''
      }}
      validate={validator}
      onSubmit={handleSubmit}
    >
      {({
        values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
        status, setFieldValue
      }) => (
        <tr>
          <td>
          </td>
          <td>
            <PartySelect
              placeholder="Select Party"
              name="party_id"
              className="select-custom-class"
              onChange={(party) => {
                setFieldValue('party_id', party.value)
                setParty(party)
              }}
              value={party}
              onBlur={handleBlur}
              valid={isSubmitting}
              invalid={(errors.party_id && touched.party_id) || !!status?.apiErrors?.party_id}
            >
            </PartySelect>
            <CFormFeedback className='invalid-feedback'>{errors.party_id || status?.apiErrors?.party_id?.join('|')}</CFormFeedback>
          </td>
          <td>
            <CFormSelect name="type"
              onChange={handleChange}
              className={`form-control form-control-sm ${isSubmitting ? 'is-valid' : ''}`}
              onBlur={handleBlur}
              value={values.type}
              placeholder='Select Type'
            >
              <option value="">Please Select</option>
              {partiesTypes.map((o, i) => <option value={o} key={o}>{o}</option>)}
            </CFormSelect>
            <CFormFeedback className='invalid-feedback'>{errors.type || status?.apiErrors?.type?.join('|')}</CFormFeedback>
          </td>
          <td>
            <CFormSwitch
              id="formSwitchCheckDefault"
              onChange={(e) => {
                setFieldValue('is_enabled', e.target.checked ? 1 : 0);
              }}
              name="is_enabled"
              checked={values.is_enabled}
              value={values.is_enabled}
              className='switch'
            />
            <CFormFeedback className='invalid-feedback'>{errors.is_enabled || status?.apiErrors?.is_enabled?.join('|')}</CFormFeedback>
          </td>
          <td>
            <CFormSelect
              onChange={handleChange}
              name="lien_waiver_type"
              options={[
                { value: '', 'label': 'Select...' },
                ...lienWaiverTypes.map(o => { return { value: o, label: o } })
              ]}
              value={values.lien_waiver_type}
              className={`form-control form-control-sm ${isSubmitting ? 'is-valid' : ''}`}
            />
            <CFormFeedback className='invalid-feedback'>{errors.lien_waiver_type || status?.apiErrors?.lien_waiver_type?.join('|')}</CFormFeedback>
          </td>
          <td>
            <label className="custom-document-upload">
              <CFormInput
                type="file"
                className='form-control-sm'
                name="lien_waiver_form"
                id="lien_waiver_form"
                onChange={(e) => { setFieldValue('lien_waiver_form', e.target.files[0]); }}
                onBlur={handleBlur}
                valid={isSubmitting}
                invalid={(errors.lien_waiver_form && touched.lien_waiver_form) || !!status?.apiErrors?.lien_waiver_form}
              />
              <CFormFeedback className='invalid-feedback'>{errors.lien_waiver_form || status?.apiErrors?.lien_waiver_form?.join('|')}</CFormFeedback>
              <CImage src={upload} />
            </label>
          </td>
          <td className='text-end'>
            <AddIconButton stylesheet={{ background: 'transparent', border: '1px solid #025346' }} imgStyles={{ width: '1.2rem', height: '1.2rem' }} onClick={handleSubmit} data="" isDisabled={isSubmitting} />
          </td>
        </tr>
      )}
    </Formik>
  )
}

export default AddFcaParty
