import classNames from 'classnames'
import React, { Component } from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { CModal, CModalBody, CModalHeader, CModalTitle, CTooltip } from '@coreui/react'

import { searchParty } from '../services/PartyService'
import AddParty from 'src/components/party/AddParty'

const customControlStyles = (base) => ({
  height: 30,
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  border: '1px solid #ccc',
})

class PartySelect extends Component {
  constructor(props) {
    super(props)
    this.searchSelectRef = React.createRef()
    this.searchParty = this.searchParty.bind(this)
    this.state = {
      loadedOptions: [],
      showModal: false,
      unlistedOption: null,
    }
  }

  componentDidMount() {
    if (this.props.autoFocus) this.searchSelectRef.current.focus()
  }

  searchParty(keyword, callback) {
    searchParty(keyword, this.props.fundsControlAccountId, this.props.types).then((response) => {
      const parties = response.data.parties
      const options = parties.map((party) => ({
        value: party.id,
        label: `${party.name}`,
      }))
      this.setState({ loadedOptions: options })
      callback(options)
    })
  }

  closeModal = () => {
    this.setState({ showModal: false }, () => {
      this.searchParty('', (options) => {
        this.setState({ loadedOptions: options })
      })
    })
  }

  handleSelectChange = (party, action) => {
    const { loadedOptions } = this.state
    const selectedOptionExists = loadedOptions?.some((option) => option?.value === party?.value)
    if (!selectedOptionExists) {
      this.setState({ showModal: true, unlistedOption: party })
    }
    this.props.onChange(party)
  }

  response = (data) => {
    this.searchParty(data.name, (options) => {
      const selectedOption = options?.find((option) => option.label.includes(data.name))

      if (selectedOption) {
        this.props.onChange(selectedOption)
      }
    })
  }

  setUnlistedOption = (unlistedOption) => {
    this.setState({ unlistedOption })
  }

  render() {
    const {
      value,
      placeholder,
      isClearable,
      invalid,
      valid,
      name,
      onBlur,
      disabled,
      label,
      autoFocus,
      width,
    } = this.props
    const { loadedOptions, showModal, unlistedOption } = this.state

    let sVal = value !== undefined ? value : null
    console.log(showModal)

    let selectComponent = (
      <div style={{ display: 'block', width }}>
        <AsyncCreatableSelect
          cacheOptions
          // defaultOptions
          components={{ IndicatorSeparator: () => null }}
          ref={this.searchSelectRef}
          onChange={this.handleSelectChange}
          isSearchable={true}
          placeholder={placeholder}
          loadOptions={this.searchParty}
          noOptionsMessage={() => 'Type to search'}
          value={sVal}
          isClearable={isClearable}
          aria-invalid={invalid ? 'true' : 'false'}
          name={name}
          className={classNames({
            'is-invalid': invalid,
            'is-valid': valid,
          })}
          classNames={{ control: (state) => 'react-select-form-control' }}
          styles={{
            control: customControlStyles,
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onBlur={() => onBlur({ target: { name, value: sVal } })}
          isDisabled={disabled}
        />
      </div>
    )

    if (sVal?.label) {
      selectComponent = (
        <CTooltip content={sVal.label} trigger={['hover', 'focus']} placement="top">
          {selectComponent}
        </CTooltip>
      )
    }

    return (
      <>
        {label && <label className="form-label">{label}</label>}
        {selectComponent}
        <CModal
          backdrop="static"
          visible={showModal}
          onClose={() => this.setState({ showModal: false })}
          className="form_modal file top"
        >
          <CModalHeader>
            <CModalTitle id="LiveDemoExampleLabel">Create Party</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <AddParty
              name={this.state.unlistedOption}
              setShowEditPopup={this.closeModal}
              pushParty={this.response}
              type={this.props.type}
              setUnlistedOption={this.setUnlistedOption}
            />
          </CModalBody>
        </CModal>
      </>
    )
  }
}

PartySelect.defaultProps = {
  autoFocus: false,
  types: [],
  onBlur: () => {},
}

export default PartySelect

{
  /*
Usage:
    <PartySelect
        callback={(r) => {
            setFieldValue('party_id', r.value);
            setParty(r)
        }}
        value={party}
        invalid={(errors.party_id && touched.party_id) || !!status?.apiErrors?.party_id}
        name="party_id"
        valid={(!errors.party_id && touched.party_id) && !status?.apiErrors?.party_id}
        onBlur={handleBlur}
    />
*/
}
