import axios from '../httpClient';

const listParties = (types = []) => axios.get('/parties?types=' + types.join(','));

const showParty = (id) => {
  let link = `/parties`
  if (id) {
    link += `/${id}`;
  }
  return axios.get(link);
}
const searchParty = (keyword, fundsControlAccountId = undefined, types = [], signal) => {
    let link = `/parties?`;
    if (keyword !== undefined) {
        link += `search=${keyword}`;
    }

    if (fundsControlAccountId !== undefined) {
        link += `&funds_control_account_id=${fundsControlAccountId}`;
    }

    if (types.length) {
        link += `&types=${types.join(',')}`;
    }

    return axios.get(link, signal);
}

const storeParty = (data) => axios.post('/parties', data,
    {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }
);

const updateParty = (id, data) => {
    if (data instanceof FormData) {
        data.append('_method', 'PUT');

        return axios.post(`/parties/${id}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
    } else {
        data['_method'] = 'PUT';
        return axios.post(`/parties/${id}`, data,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }
        );
    }
}

const deleteParty = (id) => axios.delete(`/parties/${id}`);

const verifyW9 = (party,data) => axios.post(`/parties/${party}/verify/w-9`, data);

const verifyAch = (party,data) => axios.post(`/parties/${party}/verify/ach-onboarding`, data);

export { deleteParty, listParties, searchParty, showParty, storeParty, updateParty, verifyW9, verifyAch };
