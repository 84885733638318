import { cilPlus } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import { CButton, CCol, CFormFeedback, CFormInput, CRow, CSpinner } from '@coreui/react';
import { Formik } from 'formik';

import { toast } from 'react-toastify';
import { laravelValidate } from 'src/laravelCompatibleValidator';
import { storeFcaAdjustment } from 'src/services/FcaInvestmentAdjustmentService';
import { handleApiErrors } from 'src/utils/HandleErrors';
import { CFormInputWithMask } from 'src/utils/IMaskValidator';
import { AddIconButton } from 'src/utils/ImageIcons';

const validator = values => {
  try {
    return laravelValidate(values, {
      'fca_investment_id': 'required|numeric|exists:fca_investments,id',
      'notes': 'nullable|string',
      'prepaid_amount': 'required|numeric',
      'investment_amount': 'required|numeric',
    });
  } catch (error) {
    console.error(error);
    return {};
  }
}

function AddFcaInvestment({ fcaInvestmentId, pushFcaAdjustment,refetchFCA,fetchInvestments }) {

  const handleSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    setSubmitting(true);
    storeFcaAdjustment(values).then(response => {
      pushFcaAdjustment(response.data.fca_investment_adjustment);
      setSubmitting(false);
      toast.success('FC Investment added successfully!');
      refetchFCA()
      fetchInvestments()
      resetForm();
    },
      err => {
        setSubmitting(false);
        handleApiErrors(err);
        setStatus({ apiErrors: err.response.data.errors })
      });
  }

  return (
    <Formik
      initialValues={{
        'fca_investment_id': fcaInvestmentId,
        'notes': '',
        'prepaid_amount': '0',
        'investment_amount': '',
      }}
      validate={validator}
      onSubmit={handleSubmit}
    >
      {({
        values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting,
        status, setFieldValue
      }) => (
        <tr>
          <td>
            <CFormInput
              name="notes"
              className='form-control-sm'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.notes}
              valid={isSubmitting}
              invalid={(errors.notes && touched.notes) || !!status?.apiErrors?.notes}
              placeholder='Select Notes'
            />
            <CFormFeedback className='invalid-feedback'>{errors.notes || status?.apiErrors?.notes?.join('|')}</CFormFeedback>
          </td>

          {/* <td>
            <CFormInputWithMask
              mask="$num"
              blocks={{
                num: {
                  // nested masks are available!
                  mask: Number,
                  thousandsSeparator: ',',
                  radix: "."
                }
              }
              }
              onAccept={
                (value, mask) => { { setFieldValue('pa', value); } { setFieldValue('prepaid_amount', mask._unmaskedValue); } }
              }
              className='form-control-sm'
              placeholder="Prepaid Amount"
              name="prepaid_amount"
              id="prepaid_amount"
              // onChange={handleChange}
              onBlur={handleBlur}
              value={values.prepaid_amount}
              valid={isSubmitting}
              invalid={(errors.prepaid_amount && touched.prepaid_amount) || !!status?.apiErrors?.prepaid_amount}
            />
            <CFormFeedback className='invalid-feedback'>{errors.prepaid_amount || status?.apiErrors?.prepaid_amount?.join('|')}</CFormFeedback>
          </td> */}
          <td>
            <CFormInputWithMask
              mask="$num"
              blocks={{
                num: {
                  // nested masks are available!
                  mask: Number,
                  thousandsSeparator: ',',
                  radix: ".",
                  max: 99999999,
                }
              }
              }
              onAccept={
                (value, mask) => { { setFieldValue('investment_amount', mask._unmaskedValue); } }
              }
              className='form-control-sm'
              placeholder="Amount"
              name="investment_amount"
              id="investment_amount"
              // onChange={handleChange}
              onBlur={handleBlur}
              value={values.investment_amount}
              valid={isSubmitting}
              invalid={(errors.investment_amount && touched.investment_amount) || !!status?.apiErrors?.investment_amount}
            />
            <CFormFeedback className='invalid-feedback'>{errors.investment_amount || status?.apiErrors?.investment_amount?.join('|')}</CFormFeedback>
          </td>
          <td className="text-end">
            <AddIconButton stylesheet={{background:'transparent',border: '1px solid #025346'}} imgStyles={{width:'1.2rem',height: '1.2rem'}} onClick={handleSubmit} data="" isDisabled={isSubmitting} />
          </td>
        </tr>
      )}
    </Formik>
  )
}

export default AddFcaInvestment
