import axios from '../httpClient';

const listFcaParties = (fundsControlAccountId) => {
    let link = '/fca-parties?';

    if (!!fundsControlAccountId) link += `&funds_control_account_id=${fundsControlAccountId}`;

    return axios.get(link);
}

const showFcaParty = (id) => axios.get(`/fca-parties/${id}`);
const searchFcaParty = (keyword, fundsControlAccountId = undefined) => {
    let link = `/fca-parties?search=${keyword}`;

    if (!!fundsControlAccountId) link += `&funds_control_account_id=${fundsControlAccountId}`;

    return axios.get(link);
}

const storeFcaParty = (data) => axios.post('/fca-parties', data, {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
});

const updateFcaParty = (id, data) => {
    if (data instanceof FormData) {
        data.append('_method', 'PUT');

        return axios.post(`/fca-parties/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    } else {
        data['_method'] = 'PUT';
        return axios.post(`/fca-parties/${id}`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    }
}

const deleteFcaParty = (id) => axios.delete(`/fca-parties/${id}`);

const sendLienWaiverForm = (id) => axios.post(`/fca-parties/${id}/lien-waiver/send`);

export { deleteFcaParty, listFcaParties, searchFcaParty, sendLienWaiverForm, showFcaParty, storeFcaParty, updateFcaParty };

