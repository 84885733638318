import axios from '../httpClient';

const listFcaInvestments = (fundsControlAccountId) => {
    let link = '/fca-investments?';

    if (!!fundsControlAccountId) link += `&funds_control_account_id=${fundsControlAccountId}`;

    return axios.get(link);
}
const listFcaInvestmentsSummary = (fundsControlAccountId) => {
    let link = '/fca-investments/summarize?';

    if (!!fundsControlAccountId) link += `funds_control_account_id=${fundsControlAccountId}`;

    return axios.get(link);
}

const showFcaInvestment = (id) => axios.get(`/fca-investments/${id}`);
const searchFcaInvestment = (keyword, fundsControlAccountId = undefined) => {
    let link = `/fca-investments?search=${keyword}`;

    if (!!fundsControlAccountId) link += `&funds_control_account_id=${fundsControlAccountId}`;

    return axios.get(link);
}

const storeFcaInvestment = (data) => axios.post('/fca-investments', data);

const updateFcaInvestment = (id, data) => axios.put(`/fca-investments/${id}`, data);

const deleteFcaInvestment = (id) => axios.delete(`/fca-investments/${id}`);

export { listFcaInvestments, storeFcaInvestment, updateFcaInvestment, searchFcaInvestment, showFcaInvestment, deleteFcaInvestment, listFcaInvestmentsSummary };
