export const BANK_CHECK_STATUS_RECONCILED = 'Reconciled';

export const DRAW_REQUEST_STATUS_DRAFT = 'Draft';
export const DRAW_REQUEST_STATUS_REJECTED = 'Rejected';
export const DRAW_REQUEST_STATUS_SUBMITTED = 'Submitted';
export const DRAW_REQUEST_STATUS_PENDING = 'Pending';
export const DRAW_REQUEST_STATUS_IN_REVIEW = 'In Review';
export const DRAW_REQUEST_STATUS_APPROVED_PENDING_LENDER = 'Approved Pending Lender';
export const DRAW_REQUEST_STATUS_PAID = 'Paid';
export const DRAW_REQUEST_STATUS_VOID = 'Void';

export const drawStatuses = [DRAW_REQUEST_STATUS_DRAFT, DRAW_REQUEST_STATUS_REJECTED, DRAW_REQUEST_STATUS_SUBMITTED, DRAW_REQUEST_STATUS_PENDING, DRAW_REQUEST_STATUS_IN_REVIEW, DRAW_REQUEST_STATUS_APPROVED_PENDING_LENDER, 'Pending Payment', DRAW_REQUEST_STATUS_PAID, DRAW_REQUEST_STATUS_VOID];
export const partiesTypes = ['Broker', 'Contractor', 'Inspector', 'Landlord', 'Lender', 'Owner', 'Seller', 'Tenant', 'Vendor', 'Surety'];

export const FUNDS_CONTROL_ACCOUNT_STATUS_DRAFT = 'Draft';

export const fcaStatuses = [FUNDS_CONTROL_ACCOUNT_STATUS_DRAFT, 'Active', 'Closed', 'Cancelled', 'Suspended',];
export const projectTypes = ['Single Family (One Unit)', 'Single Family (Two Units)', 'Single Family (Two to Four Units)', 'Multi-Family', 'Commercial-Office', 'Commercial-Retail', 'Commercial-Industrial', 'A&D', 'Other'];
export const constructionTypes = ['Ground Up Construction', 'Renovation', 'Addition'];

export const SERVICE_TYPE_FUND_CONTROL_NON_CHECKS = 'Fund Control (non-checks)';
export const SERVICE_TYPE_FUND_CONTROL_CHECKS = 'Fund Control (checks)';
export const serviceTypes = [SERVICE_TYPE_FUND_CONTROL_NON_CHECKS, SERVICE_TYPE_FUND_CONTROL_CHECKS , 'Inspection Only', 'PCR Reports'];

export const fcaServiceTypes = ['Fund Control (non-checks)', 'Fund Control (checks)'];
export const inspectionServiceTypes = ['Inspection Only'];

export const LIEN_WAIVER_TYPE_CONDITIONAL_FINAL = 'Conditional Final';
export const LIEN_WAIVER_TYPE_CONDITIONAL_PROGRESS = 'Conditional Progress';
export const LIEN_WAIVER_TYPE_UNCONDITIONAL_FINAL = 'Unconditional Final';
export const LIEN_WAIVER_TYPE_UNCONDITIONAL_PROGRESS = 'Unconditional Progress';

export const lienWaiverTypes = [
  LIEN_WAIVER_TYPE_CONDITIONAL_FINAL,
  LIEN_WAIVER_TYPE_CONDITIONAL_PROGRESS,
  LIEN_WAIVER_TYPE_UNCONDITIONAL_FINAL,
  LIEN_WAIVER_TYPE_UNCONDITIONAL_PROGRESS,
];

export const retainageAmountOptions = [
  { label: '3.00%', value: '3.00' },
  { label: '5.00%', value: '5.00' },
  { label: '7.00%', value: '7.00' },
  { label: 'No retainage', value: '0.00' }
];


// PCR Types
export const GC_BUDGET_TYPE_SOFT = 'Soft';
export const GC_BUDGET_TYPE_DIRECT = 'Direct';
export const GC_BUDGET_TYPE_INDIRECT = 'Indirect';

export const gcBudgetTypes = [
  GC_BUDGET_TYPE_SOFT,
  GC_BUDGET_TYPE_DIRECT,
  GC_BUDGET_TYPE_INDIRECT,
];
export const reviewTypes = ['Budget Analysis', 'General Project Analysis', 'Comprehensive Project Analysis'];
export const loanTypes = ['Conventional', 'Private', 'LIHTC', 'SBA',];
export const propertyTypes = ['Residential', 'Commercial', 'Multi-Family', 'Mixed Use', 'LIHTC'];
export const relationTypes = ['Unrelated', 'Owner/Builder', 'Unknown'];
export const sowTypes = ['Addition', 'ADU', 'Core & Shell', 'Core & Shell + Tenant Improvement', 'New Construction', 'Renovation / Remodel', 'Tenant Improvement', 'Other'];
export const pcrProjectTypes = ['A&D: Land & Site Development', 'ADU ', 'Adult Care', 'Building Renovation', 'Church', 'Condo', 'Convenience Store', 'Education', 'Fast Food', 'Gas Station', 'Gas Station/Convenience Store', 'Grocery Store', 'Historical', 'Hospitality', 'Industrial', 'Medical & Dental', 'Mixed-Use', 'Modular', 'Movie Theatre', 'Multi-Family: Garden Style', 'Multi-Family: Urban', 'Office', 'Other', 'Parking Structure', 'Plex', 'Restaurant', 'Retail', 'Single Family: Custom Home', 'Single Family: Track Home', 'Storage', 'Townhome', 'Veterinary', 'Warehouse', 'Warehouse/Office'];
export const foundationTypes = ['Slab on Grade', 'Post & Beam', 'Piles/Piers & Slab on Grade', 'Post Tension - Elevated', 'Post Tension - Slab on Grade', 'CMU', 'Existing', 'Hybrid: SOG + P&B', 'Unknown'];
export const structureTypes = ['Wood Framing', 'CMU', 'CIP Concrete', 'Existing', 'Metal Stud', 'N/A', 'PEMB', 'PEMB & Steel', 'PEMB & Wood', 'PT Deck & Concrete', 'PT Deck & Steel', 'PT Deck & Wood', 'Seismic Upgrade', 'Steel', 'Tilt-Up Concrete', 'Wood & Steel', 'Wood & Steel & CMU'];
export const facadeTypes = ['Brick/Stone', 'Existing', 'Hardie', 'Masonry', 'Metal Panel & Stucco', 'Wood', 'Combination', 'Other', 'N/A'];
export const roofTypes = ['Asphalt Shingle', 'Existing', 'Metal', 'N/A', 'Other', 'Standing Seam Metal', 'TPO', 'Combination'];
export const basementTypes = ['Yes', 'No', 'N/A'];
export const parkingTypes = ['Attached Garage', 'Detached Garage', 'Underground Garage', 'Parking Lot', 'Street Parking', 'Combination', 'None', 'Not Applicable'];
export const topographyTypes = ['Flat', 'Gentle Slope', 'Steep Slope', 'Unknown', 'Not Applicable to Scope of Work'];
export const currentConditionTypes = ['Undeveloped', 'Developed + Demo', 'Developed w/o Demo', 'Unknown',];
export const areaTypes = ['Urban', 'Rural'];
export const documentTypes = [
  'Current Schedule',
  'Final Plan',
  'Building Permit',
  'General Plan',
  'Specification Plan',
  'Architectural Plan',
  'Structural Plan',
  'MEP Plan',
  'Mechanical Plan',
  'Electrical Plan',
  'Plumbing Plan',
  'Permit',
  'Construction Contract',
  'Construction Budget',
  'Development Budget',
  'Other'
];
export const PCR_CATEGORY_CONTRACTOR_FEES = 'Contractor Fees: GC, Overhead, Profit)'

export const BUDGET_TRANSFER_STATUS_DRAFT = 'Draft';
export const BUDGET_TRANSFER_STATUS_CONTRACTOR = 'Pending GC';
export const BUDGET_TRANSFER_STATUS_OWNER = 'Pending Owner';
export const BUDGET_TRANSFER_STATUS_LENDER = 'Pending Lender';
export const BUDGET_TRANSFER_STATUS_LMFCE = 'Pending LMFCE';
export const BUDGET_TRANSFER_STATUS_APPROVED = 'Approved';
export const BUDGET_TRANSFER_STATUS_VOID = 'Void';
export const BUDGET_TRANSFER_STATUS_PENDING = 'Pending';

export const budgetTransferStatuses = [BUDGET_TRANSFER_STATUS_DRAFT, BUDGET_TRANSFER_STATUS_CONTRACTOR, BUDGET_TRANSFER_STATUS_OWNER, BUDGET_TRANSFER_STATUS_LENDER, BUDGET_TRANSFER_STATUS_LMFCE, BUDGET_TRANSFER_STATUS_APPROVED, BUDGET_TRANSFER_STATUS_VOID];
export const budgetTransferNewStatuses = [BUDGET_TRANSFER_STATUS_PENDING, BUDGET_TRANSFER_STATUS_APPROVED, BUDGET_TRANSFER_STATUS_VOID];

// Permission constants
export const PERMISSION_PUBLIC_FORM_SUBMISSION_LIST = 'Entity:PublicFormSubmission:list';
export const PERMISSION_PUBLIC_FORM_SUBMISSION_CREATE = 'Entity:PublicFormSubmission:create';
export const PERMISSION_FUNDS_CONTROL_ACCOUNT_LIST = 'Entity:FundsControlAccount:list';
export const PERMISSION_FUNDS_CONTROL_ACCOUNT_CREATE = 'Entity:FundsControlAccount:create';
export const PERMISSION_PRE_CONSTRUCTION_REVIEW_LIST = 'Entity:PreConstructionReview:list';
export const PERMISSION_PRE_CONSTRUCTION_REVIEW_CREATE = 'Entity:PreConstructionReview:create';
export const PERMISSION_PARTY_LIST = 'Entity:Party:list';
export const PERMISSION_PARTY_CREATE = 'Entity:Party:create';
export const PERMISSION_DRAW_REQUEST_LIST = 'Entity:DrawRequest:list';
export const PERMISSION_DRAW_REQUEST_CREATE = 'Entity:DrawRequest:create';
export const PERMISSION_RECEIPT_LIST = 'Entity:Receipt:list';
export const PERMISSION_RECEIPT_CREATE = 'Entity:Receipt:create';
export const PERMISSION_BANK_CHECK_LIST = 'Entity:BankCheck:list';
export const PERMISSION_BANK_CHECK_CREATE = 'Entity:BankCheck:create';
export const PERMISSION_INSPECTION_LIST = 'Entity:Inspection:list';
export const PERMISSION_INSPECTION_CREATE = 'Entity:Inspection:create';
export const PERMISSION_BANK_LIST = 'Entity:Bank:list';
export const PERMISSION_BANK_CREATE = 'Entity:Bank:create';
export const PERMISSION_BANK_ACCOUNT_LIST = 'Entity:BankAccount:list';
export const PERMISSION_BANK_ACCOUNT_CREATE = 'Entity:BankAccount:create';
export const PERMISSION_ROLE_PERMISSION_LIST = 'Entity:RolePermission:list';
export const PERMISSION_ROLE_PERMISSION_CREATE = 'Entity:RolePermission:create';
export const PERMISSION_OFFICE_LIST = 'Entity:Office:list';
export const PERMISSION_OFFICE_CREATE = 'Entity:Office:create';
export const PERMISSION_DOCUMENT_LIST = 'Entity:Document:list';
export const PERMISSION_DOCUMENT_CREATE = 'Entity:Document:create';
export const PERMISSION_USER_LIST = 'Entity:User:list';
export const PERMISSION_USER_CREATE = 'Entity:User:create';
export const PERMISSION_REPORT_CONSTRUCTION_COST_VIEW = 'Report:ConstructionCost:view';
export const PERMISSION_REPORT_PORTFOLIO_RECAP_VIEW = 'Report:PortfolioRecap:view';
export const PERMISSION_REPORT_BANK_ACCOUNT_VIEW = 'Report:BankAccount:view';
export const PERMISSION_REPORT_BANK_CHECK_VIEW = 'Report:BankCheck:view';
export const PERMISSION_REPORT_INSPECTION_VIEW = 'Report:Inspection:view';
export const PERMISSION_REPORT_FCA_BALANCE_SHEET_VIEW = 'Report:FcaBalanceSheet:view';
export const PERMISSION_REPORT_FCA_TRANSACTION_VIEW = 'Report:FcaTransaction:view';
export const PERMISSION_REPORT_RECONCILIATION = 'Entity:Reconciliation:list';


export const BANK_ACCOUNT_TYPE_CHECKING = 'Checking';
export const BANK_ACCOUNT_TYPE_SAVINGS = 'Savings';

export const POSSIBLE_BANK_ACCOUNT_TYPES = [
  BANK_ACCOUNT_TYPE_CHECKING,
  BANK_ACCOUNT_TYPE_SAVINGS,
];

export const BANK_ACCOUNT_FOR_INDIVIDUAL = 'Individual';
export const BANK_ACCOUNT_FOR_BUSINESS = 'Business';

export const POSSIBLE_BANK_ACCOUNT_FOR = [
  BANK_ACCOUNT_FOR_INDIVIDUAL,
  BANK_ACCOUNT_FOR_BUSINESS,
];

export const DEPOSIT = 'Deposit';
export const DISBURSEMENT = 'Disbursement';
